export default [
  { label: 'Construction', id: '1' },
  { label: 'Manufacturing', id: '2' },
  { label: 'Retail', id: '3' },
  { label: 'Logistics', id: '4' },
  { label: 'Finance', id: '5' },
  { label: 'Real Estate', id: '6' },
  { label: 'Food Service', id: '7' },
  { label: 'Personal Care Services', id: '8' },
  { label: 'Ambulatory Health Care', id: '9' },
  { label: 'Secondary Health Care', id: '10' },
  { label: 'Primary Health Care', id: '11' },
  { label: 'Nursing and Residential Care', id: '12' },
  { label: 'Hospitality', id: '13' },
  { label: 'Other', id: '14' }
];























































import Vue from 'vue';
import { Modal, ButtonField, Card } from 'rm-design';
import { resumeSubscription } from '@/api/subscriptions';

export default Vue.extend({
  name: 'ReactivateSubscriptionModal',
  components: { Modal, ButtonField, Card },
  props: {
    subscriptionId: {
      type: String,
      default: ''
    },
    isMonthlySub: {
      type: Boolean
    },
    showPlan: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    cost(): string {
      return this.$store.state.systemCost.toFixed(2);
    },
    userCount(): number {
      return this.$store.state.systemWorkforceLevel;
    }
  },
  methods: {
    async onConfirm() {
      this.loading = true;
      const res = await resumeSubscription(this.subscriptionId);
      this.loading = false;
      if (!res || res.error) {
        this.$snotify.error(
          res ? res.message : 'Failed to resume subscription',
          'Error'
        );
        return;
      }
      this.$snotify.success('Reactivated subscription', 'Success');
      this.$emit('reactivated');
      this.$emit('close');
    }
  }
});

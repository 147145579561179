















































import Vue, { PropType } from 'vue';
import { Overlay, ButtonField, SelectField } from 'rm-design';
import { IPaymentFilters } from './interfaces';
import paymentStatusOptions from './constants/paymentStatusTypes';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import paymentFilterOverlayIds from './constants/ids/paymentFilterOverlayIds';

export default Vue.extend({
  name: 'PaymentFilterOverlay',
  components: { Overlay, ButtonField, SelectField },
  props: {
    filters: {
      type: Object as PropType<IPaymentFilters>,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    filters: {
      handler() {
        this.overlayFilterValues = cloneDeep(this.filters);
      },
      deep: true
    }
  },
  data() {
    return {
      paymentFilterOverlayIds,
      paymentStatusOptions,
      planTypeOptions: [
        {
          id: '1',
          label: 'System'
        },
        {
          id: '2',
          label: 'SMS'
        }
      ],
      overlayFilterValues: {
        status: null,
        subscriptionType: null
      } as IPaymentFilters
    };
  },
  computed: {
    filtersChanged(): boolean {
      const statusChanged = !isEqual(
        this.overlayFilterValues.status,
        this.filters.status
      );

      const subscriptionTypeChanged = !isEqual(
        this.overlayFilterValues.subscriptionType,
        this.filters.subscriptionType
      );

      return statusChanged || subscriptionTypeChanged;
    }
  },
  methods: {
    onClearFilters() {
      const clearFilters: IPaymentFilters = {
        subscriptionType: null,
        status: null
      };
      this.$emit('setFilters', clearFilters);
    },
    onSetFilters() {
      this.$emit('setFilters', this.overlayFilterValues);
      this.$emit('close');
    }
  }
});

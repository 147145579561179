var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-step-indicators__wrapper"},_vm._l((_vm.formSteps),function(formStep,index){return _c('div',{key:index,staticClass:"form-step-indicator",class:{
      'form-step-indicator--active': formStep.step === _vm.activeFormStep
    },staticStyle:{"padding":"12px"}},[_c('div',{staticClass:"form-step-indicator__number",class:{
        'rm-bck-purple--100': formStep.step === _vm.activeFormStep,
        'rm-bck-green--100': formStep.complete,
        'rm-bck-black--30':
          !formStep.complete && formStep.step !== _vm.activeFormStep
      }},[_c('p',{class:[
          formStep.step === _vm.activeFormStep || formStep.complete
            ? 'rm-white'
            : 'rm-black--100'
        ]},[(formStep.complete)?_c('i',{staticClass:"fa fa-check"}):_c('b',[_vm._v(" "+_vm._s(formStep.step)+" ")])])]),_c('p',{staticClass:"rm-black--100 rm-ml--8"},[_c('b',[_vm._v(_vm._s(formStep.name))])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="empty-organisations__container rm-p-sides--16">
    <div class="rm-mt--40 rm-mb--20">
      <img :src="expired" alt="No active organisations" />
    </div>
    <h3 class="rm-mt--40">No active organisations :(</h3>
    <p class="rm-mb--40 rm-mt--20">
      Sorry, you have no organisations that are set up for automated billing. For any manual billing queries please contact your Rotify account manager.
    </p>
    <ButtonField
      text="Log Out"
      ref="logOutBtn"
      class="rm-m-ends--40"
      @click="logOut"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import expired from '@/assets/expired.svg';
import { ButtonField } from 'rm-design';
import { mapActions } from 'vuex';

export default Vue.extend({
  name: 'EmptyOrganisationsState',
  components: {
    ButtonField
  },
  data() {
    return {
      expired
    };
  },
  methods: {
    ...mapActions(['logOut'])
  }
});
</script>

<style scoped>
.empty-organisations__container {
  text-align: center;
  margin: auto;
  width: 70%;
}
</style>

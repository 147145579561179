
















































import Vue from 'vue';
import { Modal, ButtonField, TextField } from 'rm-design';

export default Vue.extend({
  name: 'DangerModal',
  components: { Modal, ButtonField, TextField },
  data() {
    return {
      password: ''
    };
  },
  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    cancelButtonText: {
      type: String,
      required: false,
      default: 'No, cancel this'
    },
    confirmButtonText: {
      type: String,
      required: false,
      default: 'Yes, Apply Changes'
    },
    dangerButton: {
      type: Boolean,
      required: false,
      default: true
    },
    dangerButtonLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    passwordRequired: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    emitSubmit() {
      if (this.passwordRequired) {
        this.$emit('submit', this.password);
      } else {
        this.$emit('submit');
      }
    }
  }
});





























import Vue from 'vue';
import { ButtonField } from 'rm-design';
import CardDetails from '@/components/PaymentDetails/CardDetails.vue';
import CardDetailsForm from '@/components/PaymentDetails/models/CardDetailsForm';
import EventHub from '@/eventHub';

export default Vue.extend({
  name: 'AddNewCardModalStep',
  components: {
    ButtonField,
    CardDetails
  },
  data() {
    return {
      cardDetailsForm: new CardDetailsForm(),
      addNewCardSubmitAttempted: false,
      addingCard: false
    };
  },
  methods: {
    onCardAdded(cardId: string) {
      this.addingCard = false;
      this.$snotify.success('Card added', 'Success');
      this.$emit('newCardSaved', cardId);
    },
    async onAddNewCard() {
      this.addingCard = true;
      EventHub.$emit('submit');
    }
  }
});

























































import Vue from 'vue';
import PersonalDetailsFormModel from './models/PersonalDetailsForm';
import { TextField, ButtonField } from 'rm-design';
import { userExists, IUserExistsArg } from '@/api/user';
import PersonalDetailsIds from './constants/PersonalDetailsIds';

export default Vue.extend({
  name: 'PersonalDetailsForm',
  components: { TextField, ButtonField },
  props: {
    form: {
      type: PersonalDetailsFormModel,
      required: true
    }
  },
  data() {
    return {
      personalDetails: this.form,
      checkingEmailValidity: false,
      submitAttempted: false,
      PersonalDetailsIds
    };
  },
  methods: {
    async onSubmit() {
      this.submitAttempted = true;
      if (!this.personalDetails.validate()) {
        return;
      }
      this.checkingEmailValidity = true;
      const userExistsArg: IUserExistsArg = {
        email: this.form.email.value,
        apiServerId: this.$store.state.apiServerId
      };
      const response = await userExists(userExistsArg);

      this.checkingEmailValidity = false;
      if (!response || response.error) {
        this.$snotify.error(
          response.message || 'Something went wrong',
          'Error'
        );
        return;
      }
      const emailAlreadyExists = response.data;
      this.$emit('continue', emailAlreadyExists);
    },
    onFieldValueChange() {
      if (this.submitAttempted) {
        this.$nextTick(() => {
          this.personalDetails.validate();
        });
      }
    }
  }
});

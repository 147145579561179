















































































import Vue, { PropType } from 'vue';
import {
  TextField,
  SelectField,
  ButtonField,
  SearchAutocomplete
} from 'rm-design';
import OrganisationDetailsFormModel from './models/OrganisationDetailsForm';
import OrganisationDetailsIds from './constants/OrganisationDetailsIds';
import { organisationNameExists, IOrgNameExistsArg } from '@/api/organisations';
import { ISelectFieldValue } from '@/components/SignUp/signUpInterfaces';
export default Vue.extend({
  name: 'OrganisationDetailsForm',
  components: { TextField, SelectField, ButtonField, SearchAutocomplete },
  props: {
    form: {
      type: OrganisationDetailsFormModel,
      required: true
    },
    timeZones: {
      type: Array as PropType<ISelectFieldValue[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      submitAttempted: false,
      organisationDetails: this.form,
      OrganisationDetailsIds
    };
  },
  methods: {
    async onSubmit() {
      this.submitAttempted = true;
      if (!this.organisationDetails.validate()) {
        this.submitAttempted = false;
        return;
      }

      const arg: IOrgNameExistsArg = {
        name: this.organisationDetails.name.value,
        apiServerId: this.$store.state.apiServerId
      };
      const orgNameExists = await organisationNameExists(arg);

      if (!orgNameExists || orgNameExists.error) {
        this.$snotify.error(orgNameExists?.message || 'Bad request', 'Error');
        this.submitAttempted = false;
        return;
      }
      if (orgNameExists.data === true) {
        this.$snotify.error('Organisation name already exists', 'Error');
        this.submitAttempted = false;
        return;
      }

      this.submitAttempted = false;
      this.$emit('submit');
    },
    onFieldChange() {
      if (this.submitAttempted) {
        this.$nextTick(() => {
          this.organisationDetails.validate();
        });
      }
    }
  },
  mounted() {
    if (!this.organisationDetails.name.value.length) {
      const element: HTMLDivElement | null = this.$el.querySelector(
        `#${OrganisationDetailsIds.Name}`
      );
      if (element) {
        element.focus();
      }
    }

    const ukTimezone = this.timeZones.find(x => x.id === 'GMT Standard Time');

    if (ukTimezone) {
      this.organisationDetails.timeZone.value = ukTimezone;
    }
  }
});



























import Vue from 'vue';
import { Overlay, ButtonField } from 'rm-design';
import CardDetailsForm from '@/components/PaymentDetails/models/CardDetailsForm';
import CardDetails from '@/components/PaymentDetails/CardDetails.vue';
import EventHub from '@/eventHub';
export default Vue.extend({
  name: 'AddCardOverlay',
  components: { Overlay, CardDetails, ButtonField },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    activeCardOption: {
      type: String,
      required: false
    },
    showActiveCardCheckbox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: new CardDetailsForm(),
      makeActiveCard: false,
      addingCard: false
    };
  },
  methods: {
    onCardAdded() {
      this.addingCard = false;
      this.$snotify.success('Card added', 'Success');
      this.$emit('addCard');
      this.$emit('close');
    },
    async onAddNewCard() {
      this.addingCard = true;
      EventHub.$emit('submit');
    },
    onClose() {
      this.$emit('close');
      this.form = new CardDetailsForm();
    }
  }
});

export default {
  Title: 'personal-details-form__title',
  FirstName: 'personal-details-form__first-name',
  LastName: 'personal-details-form__last-name',
  Email: 'personal-details-form__email-address',
  PhoneNumber: 'personal-details-form__phone-number',
  ContinueButton: 'personal-details-form__continue-button',
  SignInButton: 'personal-details-form__sign-in-button',
  GoBackButton: 'personal-detauls-form__go-back-button'
};

























import Vue from 'vue';
import { SearchAutocomplete } from 'rm-design';
import { mapActions } from 'vuex';
import { IOrganisation } from '@/interfaces/organisation';
import ChangeOrganisationIDs from './constants/IDs/ChangeOrganisationIDs';

export default Vue.extend({
  name: 'ChangeOrganisation',
  components: {
    SearchAutocomplete
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.setOrganisations();
      }
    }
  },
  data() {
    return {
      loading: false,
      ChangeOrganisationIDs,
      organisation: null,
      organisations: [] as IOrganisation[]
    };
  },
  mounted() {
    this.setOrganisations();
  },
  computed: {
    title(): string {
      return this.isLogin
        ? 'Please select your organisation'
        : 'Change Organisation';
    },
    body(): string {
      return this.isLogin
        ? 'You need to select an organisation to be redirected to the correct system.'
        : 'Please select an organisation to change to:';
    }
  },
  props: {
    isLogin: {
      type: Boolean,
      required: false,
      default: false
    },
    show: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    ...mapActions(['setOrganisation']),
    async changeOrganisation(e) {
      if (e) {
        this.loading = true;
        const organisation: IOrganisation | undefined = this.organisations.find(
          c => c.id === e.id
        );

        await this.setOrganisation(organisation);
        this.loading = false;
        this.$emit('close');
      }
    },
    setOrganisations() {
      this.organisations = this.$store.state.organisations.map(x => ({
        ...x,
        label: x.name
      }));
    }
  }
});



















































import Vue from 'vue';
import { ButtonField, TextField } from 'rm-design';
import { sendVerificationCode } from '@/api/auth';
import { verify } from '@/api/user';
import MailOpenedImage from '@/assets/mail-opened.png';
import MailSentImage from '@/assets/mail-sent.png';
import VerificationFormIds from './constants/VerificationFormIds';

export default Vue.extend({
  name: 'VerificationForm',
  components: { ButtonField, TextField },
  props: {
    userEmail: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      verificationEmailSent: false,
      verifying: false,
      sendingVerificationCode: false,
      MailOpenedImage,
      MailSentImage,
      verificationCode: '',
      VerificationFormIds,
      verificationCodeErrorMessage: ''
    };
  },
  methods: {
    async sendVerificationEmail() {
      const details = {
        username: this.userEmail,
        apiServerId: this.$store.state.apiServerId
      };
      this.sendingVerificationCode = true;
      this.verificationCode = '';
      const res = await sendVerificationCode(details);
      this.sendingVerificationCode = false;
      if (res && !res.error) {
        this.$snotify.success('Verification email sent', 'Success');
        this.verificationEmailSent = true;
      } else {
        this.$snotify.error(
          res ? res.message : 'Failed to send verification email',
          'Error'
        );
      }
    },
    async checkVerificationCode() {
      if (this.verificationCode.length < 6) {
        this.verificationCodeErrorMessage = 'Invalid code';
        return;
      }
      this.verificationCodeErrorMessage = '';
      this.verifying = true;
      const arg = {
        verificationCode: this.verificationCode,
        username: this.userEmail,
        apiServerId: this.$store.state.apiServerId
      };
      const response = await verify(arg);
      this.verifying = false;
      if (response && !response.error) {
        this.$snotify.success('Successfully verified', 'Success');
        this.$emit('continue');
      } else {
        this.$snotify.error(
          response ? response.message : 'Failed to verify',
          'Error'
        );
        this.verificationCodeErrorMessage = response
          ? response?.message
          : 'Failed to verify';
      }
    }
  }
});





























































































import Vue from 'vue';
import { Card, ButtonField } from 'rm-design';
import FormStepIndicator from '@/components/Subscribe/FormStepIndicator.vue';
import SubscriptionSummary from '@/components/Subscribe/SubscriptionSummary.vue';
import {
  IFormStep,
  ICardOption
} from '@/components/Subscribe/constants/SubscribeInterfaces';
import PlanCustomisation from '@/components/Subscribe/FormSteps/PlanCustomisation.vue';
import PaymentDetails from '@/components/Subscribe/FormSteps/PaymentDetails.vue';
import {
  getProjectInvoice,
  getUpperBandLimit,
  updateBillingDetails,
  getStripeBillingDetails,
  getPlans,
  ISystemPlan
} from '@/api/stripe';
import BillingDetailsForm from '@/components/PaymentDetails/models/BillingDetailsForm';
import SubscribeSkel from '@/components/Subscribe/SubscribeSkel.vue';
import debounce from 'lodash.debounce';
import { createSubscription } from '@/api/subscriptions';
import { ISelectField } from '@/interfaces/common';
import SelectFeatures from '@/components/Subscribe/FormSteps/SelectFeatures.vue';
import OrganisationCreated from '@/components/SignUp/OrganisationCreated.vue';
import SlideshowSplitLayout from '@/components/SlideshowSplitLayout/SlideshowSplitLayout.vue';
import { mapActions } from 'vuex';
import { getSystemCountries } from '@/api/organisations';

const formSteps: IFormStep[] = [
  { step: 1, name: 'Plan Customisation' },
  { step: 2, name: 'Select Features' },
  { step: 3, name: 'Payment Details' }
];

export default Vue.extend({
  name: 'Subscribe',
  components: {
    FormStepIndicator,
    SubscriptionSummary,
    Card,
    PlanCustomisation,
    PaymentDetails,
    SubscribeSkel,
    ButtonField,
    SelectFeatures,
    OrganisationCreated,
    SlideshowSplitLayout
  },
  data() {
    return {
      loading: true,
      formSteps,
      currentStep: formSteps[0] as IFormStep,
      paymentFrequency: null as any,
      employeeCount: 1,
      savingCard: false as boolean,
      useSms: false as boolean,
      newPlanCardDetailsConfirmed: false as boolean,
      planCardId: '',
      costWithoutVat: '0',
      costWithVat: '0',
      upperBandLimit: 0,
      paymentFrequencyOptions: [] as ISelectField[],
      creatingSubscription: false,
      systemPlans: [] as ISystemPlan[],
      additionalPlans: [] as ISystemPlan[],
      smsPlans: [] as ISystemPlan[],
      chosenSystemPlan: {} as ISystemPlan,
      chosenAdditionalPlans: [] as ISystemPlan[],
      paymentAttemptMade: false,
      paymentFailure: false,
      requiresAction: false,
      paymentFailureUrl: '',
      billingDetails: new BillingDetailsForm()
    };
  },
  async mounted() {
    await this.setPaymentFrequencyOptions();
    await this.getPlans();
    await this.getPlanAndValue();

    var countryResponse = await getSystemCountries();

    if (countryResponse.data) {
      this.billingDetails.country.options = countryResponse.data;
    }

    if (this.orgAlreadyExists) {
      await this.setBillingDetails();
    }
    this.loading = false;
  },
  computed: {
    nextStep(): string {
      const nextStep = this.formSteps.find(
        step => step.step === this.currentStep.step + 1
      );
      return nextStep ? `${nextStep.step}. ${nextStep.name}` : '';
    },
    orgAlreadyExists(): boolean {
      return !this.$route.query.orgId;
    },
    creationHeaderText(): string {
      return this.paymentFailure
        ? 'Oops! Subscription not active :('
        : 'Subscription now active :)';
    },
    showPaymentButton(): boolean {
      return this.currentStep.step === 3;
    },
    paymentButtonDisabled(): boolean {
      if (!this.planCardId) {
        return true;
      }
      return false;
    },
    savedCardOptions(): ICardOption[] {
      return this.$store.state.paymentCards.map(card => ({
        label: `**** **** **** ${card.numberLastFour} ${card.brand}`,
        id: card.id
      }));
    },
    planPaymentCard(): ICardOption | undefined {
      return this.savedCardOptions.find((card: ICardOption) => {
        return card.id === this.planCardId;
      });
    },
    additionalPlanNames(): string[] {
      return this.additionalPlans
        .filter(x => this.chosenAdditionalPlans.includes(x))
        .map(x => x.name);
    }
  },
  methods: {
    ...mapActions(['setPaymentCards']),
    async getPlans() {
      const planRes = await getPlans();
      if (!planRes || !planRes.error) {
        if (planRes.data) {
          this.systemPlans = planRes.data.systemPlans;
          this.additionalPlans = planRes.data.additionalPlans;
          this.smsPlans = planRes.data.smsPlans;
          this.setSystemPlan(this.systemPlans[0].id);
        }
      }
    },
    onSelectedCardChange(cardId: string) {
      this.planCardId = cardId;
    },
    async onSystemPlanChange(planId: string) {
      this.setSystemPlan(planId);
      this.getPlanAndValue();
    },
    onAdditionalPlanChange(planId) {
      if (!this.chosenAdditionalPlans.includes(planId)) {
        this.chosenAdditionalPlans.push(planId);
      } else {
        this.chosenAdditionalPlans = this.chosenAdditionalPlans.filter(
          x => x !== planId
        );
      }
      this.getPlanAndValue();
    },
    setSystemPlan(planId: string) {
      const plan = this.systemPlans.find(x => x.id === planId);
      if (plan) {
        this.chosenSystemPlan = plan;
      }
    },
    async setPaymentFrequencyOptions() {
      this.paymentFrequencyOptions = [
        {
          id: '1',
          label: 'Monthly'
        },
        {
          id: '2',
          label: 'Annually'
        }
      ];

      this.paymentFrequency = this.paymentFrequencyOptions[0];
    },
    async onMakePayment() {
      if (this.billingDetails.validate()) {
        this.creatingSubscription = true;
        const systemSubscriptionRes = await createSubscription({
          monthly:
            this.paymentFrequency && this.paymentFrequency.label !== 'Annually',
          additionalPlans: this.chosenAdditionalPlans.map(x => x.id),
          planId: this.chosenSystemPlan.id,
          cardId: this.planCardId,
          workforceLevel: this.employeeCount
        });

        if (!systemSubscriptionRes || systemSubscriptionRes.error) {
          this.creatingSubscription = false;
          this.$snotify.error(
            systemSubscriptionRes
              ? systemSubscriptionRes.message
              : 'Could not create subscription',
            'Error'
          );
          return;
        }

        try {
          await updateBillingDetails({
            line1: this.billingDetails.addressLine1.value,
            line2: this.billingDetails.addressLine2.value,
            city: this.billingDetails.city.value,
            country: this.billingDetails.country.value?.id ?? '',
            postalCode: this.billingDetails.postCode.value,
            vatNumber: this.billingDetails.vatNumber.value
          });
        } catch (ex) {
          // do nothing
        }

        if (this.useSms && systemSubscriptionRes.data.paymentSuccess) {
          await createSubscription({
            planId: this.smsPlans[0].id,
            cardId: this.planCardId
          });
        }

        this.creatingSubscription = false;
        if (!systemSubscriptionRes.data.paymentSuccess) {
          this.paymentFailure = true;
          this.requiresAction = systemSubscriptionRes.data.requiresAction;
          this.paymentFailureUrl = systemSubscriptionRes.data.hostedInvoiceUrl;
        } else {
          this.paymentFailure = false;
        }
        this.paymentAttemptMade = true;
      }
    },
    debouncedGetPlanBAndValue: debounce(function(this: any) {
      this.getPlanAndValue();
    }, 1000),
    onEmployeeCountChange(value: number) {
      this.employeeCount = value;
      this.debouncedGetPlanBAndValue();
    },
    onPaymentFrequencyChange(value: string) {
      const option = this.paymentFrequencyOptions.find(x => x.label === value);
      if (option) {
        this.paymentFrequency = option;
        this.getPlanAndValue();
      }
    },
    async setBillingDetails() {
      const billingDetailsRes = await getStripeBillingDetails();

      if (billingDetailsRes && !billingDetailsRes.error) {
        if (billingDetailsRes.data) {
          this.billingDetails.addressLine1.value = billingDetailsRes.data.line1;
          this.billingDetails.addressLine2.value = billingDetailsRes.data.line2;
          this.billingDetails.city.value = billingDetailsRes.data.city;
          const chosenCountry = this.billingDetails.country.options?.find(
            x => x.id === billingDetailsRes.data?.country
          );
          if (chosenCountry) {
            this.billingDetails.country.value = chosenCountry;
          }
          this.billingDetails.postCode.value =
            billingDetailsRes.data.postalCode;
          this.billingDetails.vatNumber.value =
            billingDetailsRes.data.vatNumber;
        }
      }
    },
    async getPlanAndValue() {
      if (isNaN(this.employeeCount) || this.employeeCount < 1) {
        return;
      }

      const data = {
        workforceLevel: this.employeeCount,
        monthly:
          this.paymentFrequency && this.paymentFrequency.label !== 'Annually',
        additionalPlans: this.chosenAdditionalPlans.map(x => x.id),
        planId: this.chosenSystemPlan.id,
        cardId: ''
      };
      const costRes = await getProjectInvoice(data);

      if (costRes && !costRes.error) {
        if (costRes.data) {
          this.costWithoutVat = costRes.data.cost.toFixed(2);
          this.costWithVat = costRes.data.costWithVat.toFixed(2);
        }
      } else {
        this.$snotify.error(
          costRes ? costRes.message : 'Failed to get cost',
          'Error'
        );
      }
      const upperBandLimitRes = await getUpperBandLimit(this.employeeCount);
      if (
        upperBandLimitRes &&
        !upperBandLimitRes.error &&
        upperBandLimitRes.data
      ) {
        this.upperBandLimit = upperBandLimitRes.data;
      } else {
        this.$snotify.error(
          upperBandLimitRes
            ? upperBandLimitRes.message
            : 'Failed to get upper limit for this band',
          'Error'
        );
      }
    },
    async onPlanCustomisationNext() {
      this.currentStep = this.formSteps[1];
    },
    async onSelectFeaturesNext() {
      if (this.orgAlreadyExists) {
        this.loading = true;
        await this.setPaymentCards();
        this.loading = false;
      }

      this.currentStep = this.formSteps[2];
    },
    onGoToDashboard() {
      this.$router.push('/').catch(() => {
        // do nothing
      });
    },
    onUseSmsChange(value: boolean) {
      this.useSms = value;
    },
    async onPlanCardAdded(cardId: string) {
      this.onSelectedCardChange(cardId);
    }
  }
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.creationIsDisabled)?_c('div',{staticClass:"register__disabled-container",style:({
    backgroundImage: ("linear-gradient(to bottom, transparent, white), url(" + _vm.EmptyStateBg + ")")
  })},[_c('img',{staticClass:"register__disabled-state-image rm-m-ends--40",attrs:{"src":_vm.BeeGraphicSvg,"alt":"Bee Flying"}}),_c('h3',[_vm._v("Sorry, we are not accepting any new trials right now!")]),_c('h3',{staticClass:"rm-mt--20"},[_vm._v("Please check back again later")]),_c('ButtonField',{staticClass:"rm-mt--24",attrs:{"text":"Close"},on:{"click":_vm.goToRotify}})],1):_c('SlideshowSplitLayout',[(_vm.gettingOrgDetails)?_c('SignUpSkeleton'):_c('div',[_c('h1',{staticClass:"sign-up__welcome-title"},[_vm._v(" "+_vm._s(_vm.headerText)+" ")]),(_vm.currentStep === _vm.SignUpStates.PersonalDetails)?_c('PersonalDetailsForm',{attrs:{"form":_vm.personalDetailsFormModel},on:{"continue":_vm.onPersonalDetailsContinue}}):_vm._e(),(
        _vm.currentStep === _vm.SignUpStates.Password &&
          !_vm.userExists &&
          !_vm.showVerificationStep
      )?_c('CreatePasswordForm',{attrs:{"form":_vm.passwordFormModel,"loading":_vm.loading},on:{"goBack":function($event){_vm.currentStep = _vm.SignUpStates.PersonalDetails},"create":_vm.createRotifyUser}}):_vm._e(),(
        _vm.currentStep === _vm.SignUpStates.Password &&
          _vm.userExists &&
          !_vm.showVerificationStep
      )?_c('ExistingUserPasswordForm',{attrs:{"email":_vm.personalDetailsFormModel.email.value,"loading":_vm.loading},on:{"goBack":function($event){_vm.currentStep = _vm.SignUpStates.PersonalDetails},"submit":_vm.authenticateExistingUser},model:{value:(_vm.existingUserPassword),callback:function ($$v) {_vm.existingUserPassword=$$v},expression:"existingUserPassword"}}):_vm._e(),(_vm.showVerificationStep)?_c('VerificationForm',{attrs:{"userEmail":_vm.personalDetailsFormModel.email.value},on:{"continue":_vm.onVerificationSuccess,"goBack":_vm.reset}}):_vm._e(),(_vm.currentStep === _vm.SignUpStates.Organisation)?_c('OrganisationDetailsForm',{attrs:{"form":_vm.organisationDetailsFormModel,"timeZones":_vm.timeZoneOptions,"loading":_vm.loading},on:{"submit":_vm.createOrganisation,"goBack":_vm.reset}}):_vm._e(),(_vm.currentStep === _vm.SignUpStates.Success)?_c('OrganisationCreated',{attrs:{"organisationName":_vm.organisationDetailsFormModel.organisationName,"userName":_vm.personalDetailsFormModel.email.value}}):_vm._e(),_c('SnakeStepTracker',{staticClass:"rm-mt--40",attrs:{"maxSteps":3,"currentStep":_vm.currentStep}}),_c('div',{staticClass:"sign-up_privacy-documents"},[_c('ButtonField',{ref:"privacy",staticClass:"rm-mt--32 rm-mb--16",attrs:{"size":"medium","type":"minimal","text":"Privacy Policy"},on:{"click":function($event){return _vm.openPrivacyPolicy()}}}),_c('ButtonField',{ref:"terms-and-conditions",staticClass:"rm-mt--32 rm-mb--16",attrs:{"size":"medium","type":"minimal","text":"Terms & Conditions"},on:{"click":function($event){return _vm.openTermsAndConditions()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
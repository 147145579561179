import axios from 'axios';
import { store } from '@/store/store.js';

const instance = axios.create({
  headers: {
    Pragma: 'no-cache',
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

const onSuccess = response => {
  return response;
};

export const onError = error => {
  // default error
  const response = { error: true, message: 'Something went wrong' };
  if (error.response) {
    const {
      response: { status }
    } = error;

    // check for expired header
    const tokenHeader = error.response.headers['token-expired'];
    if (status === 401 && tokenHeader !== null && tokenHeader) {
      location.href = '/login';
      response.error = true;
      response.message = error.response.data[0]
        ? error.response.data[0].message
        : '';
    } else if (typeof error.response.data === 'string') {
      response.error = true;
      response.message =
        error.response.data === '' ? 'Invalid Request!' : error.response.data;
      return response;
    } else if (typeof error.response.data === 'object') {
      response.error = true;
      response.message = error.response.data[0].message;
      return response;
    }
  }
};

instance.interceptors.request.use(config => {
  config.baseURL = store.state.apiUrl;

  if (config.headers.Authorization !== null) {
    if (store.state.token) {
      config.headers.Authorization = `Bearer ${store.state.token}`;
    }
  }

  return config;
});

instance.interceptors.response.use(onSuccess, onError);

export const httpHandler = instance;

import { httpHandler } from '@/utils/ApiResponseHandler';
import {
  IRegistrationAPIConfig,
  IOrganisationStripeSignUpConfig
} from '@/components/SignUp/signUpInterfaces';
import { IApiResponse } from '@/interfaces/common';

export interface IOrgNameExistsArg {
  name: string;
  apiServerId: string;
}
export const organisationNameExists = (
  arg: IOrgNameExistsArg
): Promise<{ data: boolean; error?: boolean; message?: string }> => {
  return httpHandler.get(
    `/Organisations/Exists/${arg.name}?apiServerId=${arg.apiServerId}`
  );
};

export const createOrg = (
  config: IRegistrationAPIConfig
): Promise<IApiResponse<any>> => {
  const data = {
    organisationName: config.organisationName,
    firstName: config.firstName,
    lastName: config.lastName,
    email: config.email,
    phone: config.phoneNumber,
    password: config.password,
    confirmPassword: config.confirmPassword,
    roleName: config.roleName,
    rotaGroupName: config.rotaGroup,
    sector: config.sector,
    timeZone: config.timeZone,
    apiServerId: config.apiServerId,
    hubspotId: config.hubspotId || null
  };

  return httpHandler.post(`/Organisations`, data);
};

export const createOrgInStripe = (
  config: IOrganisationStripeSignUpConfig
): Promise<IApiResponse<any>> => {
  return httpHandler.post(`/Organisations/Stripe`, config);
};
export interface IGetOrgDetailsArg {
  orgId: string;
  userId: string;
  apiServerId: string;
}

export interface ICountry {
  id: string;
  label: string;
}

export const getOrgDetails = (
  arg: IGetOrgDetailsArg
): Promise<IApiResponse<any>> => {
  return httpHandler.get(
    `/organisations/trial?id=${arg.orgId}&userId=${arg.userId}&apiServerId=${arg.apiServerId}`
  );
};

export const getDefaultServer = (): Promise<IApiResponse<any>> => {
  return httpHandler.get(`/apiserver/default`);
};

export const getSystemTimezones = (): Promise<IApiResponse<any>> => {
  return httpHandler.get(`/timezones`);
};

export const getSystemCountries = (): Promise<IApiResponse<ICountry[]>> => {
  return httpHandler.get(`/timezones/countries`);
};

export const getCurrentWorkforceLevel = (
  orgId: string
): Promise<IApiResponse<any>> => {
  return httpHandler.get(`/organisations/workforcelevel?orgId=${orgId}`);
};

export const creationIsEnabled = (
  serverId: string
): Promise<IApiResponse<any>> => {
  return httpHandler.get(`/ApiServer/CreationEnabled/${serverId}`);
};



















































import Vue from 'vue';
import CheckListItem, { StatusTypes } from './CheckListItem.vue';
import PasswordFormModel, { SpecialCharsString } from './models/PasswordForm';

export default Vue.extend({
  name: 'PasswordCheckList',
  components: { CheckListItem },
  props: {
    form: {
      type: PasswordFormModel,
      required: true
    }
  },
  data() {
    return {
      passwordForm: this.form,
      SpecialCharsString
    };
  },
  computed: {
    passwordFieldsAreEmpty(): boolean {
      return (
        this.passwordForm.password.value.length === 0 &&
        this.passwordForm.confirmPassword.value.length === 0
      );
    }
  },
  methods: {
    getCheckItemStatus(isSuccess: boolean): string {
      if (
        this.passwordForm.password.value.length === 0 &&
        this.passwordForm.confirmPassword.value.length === 0
      ) {
        return StatusTypes.Default;
      }
      return isSuccess ? StatusTypes.Success : StatusTypes.Error;
    }
  }
});

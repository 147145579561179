























































































































import Vue from 'vue';
import { SelectField, ButtonField, TextField } from 'rm-design';
import CardDetails from '@/components/PaymentDetails/CardDetails.vue';
import CardDetailsForm from '@/components/PaymentDetails/models/CardDetailsForm';
import { ICardOption } from '@/components/Subscribe/constants/SubscribeInterfaces';
import EventHub from '@/eventHub';
import { createOrgInStripe, ICountry } from '@/api/organisations';
import BillingDetailsForm from '@/components/PaymentDetails/models/BillingDetailsForm';

export default Vue.extend({
  name: 'PaymentDetails',
  components: { SelectField, ButtonField, CardDetails, TextField },
  props: {
    savedCardOptions: {
      type: Array as () => ICardOption[],
      default() {
        return [];
      }
    },
    selectedCardId: {
      type: String,
      default: ''
    },
    createOrg: {
      type: Boolean,
      default: false
    },
    billingDetails: {
      type: BillingDetailsForm,
      required: true
    }
  },
  computed: {
    billingForm: {
      get(): BillingDetailsForm {
        return this.billingDetails;
      },
      set(val: BillingDetailsForm) {
        this.$emit('input', val);
      }
    }
  },
  data() {
    return {
      planCardDetails: new CardDetailsForm(),
      selectedPaymentCard: {} as ICardOption | null,
      loading: false,
      newPlanCardDetailsConfirmed: false,
      showPaymentDetailsAddNewCardForm: false,
      countryOptions: [] as ICountry[]
    };
  },
  async mounted() {
    if (this.createOrg) {
      const stripeResponse = await createOrgInStripe({
        organisationId: this.getOrgId(),
        userId: this.getUserId(),
        apiServerId: this.$store.state.apiServerId
      });

      if (stripeResponse.error) {
        this.$snotify.error(
          `Failed to create organisation in stripe - ${stripeResponse.message}`,
          'Error'
        );
      } else {
        this.$store.commit('setToken', stripeResponse.data.token);
        this.$store.commit('setUserEmail', stripeResponse.data.email);
        this.$store.commit('setOrganisation', {
          name: stripeResponse.data.name
        });
      }
    }

    if (this.savedCardOptions.length < 1) {
      this.showPaymentDetailsAddNewCardForm = true;
    }

    this.loadCard();
  },
  watch: {
    selectedCardId(selectedCardId) {
      if (selectedCardId !== '') {
        this.loadCard();
      }
    }
  },
  methods: {
    onSelectedPaymentCardChange(card) {
      this.newPlanCardDetailsConfirmed = false;
      this.showPaymentDetailsAddNewCardForm = false;
      const cardId: string = card ? card.id : '';
      this.$emit('selectedCardChange', cardId);
    },
    loadCard() {
      if (this.selectedCardId !== '') {
        const selectedCard = this.savedCardOptions.find(
          x => x.id === this.selectedCardId
        );
        this.selectedPaymentCard =
          selectedCard !== undefined ? selectedCard : null;
      }
    },
    onCardAdded(cardId) {
      this.loading = false;
      this.newPlanCardDetailsConfirmed = true;
      this.$snotify.success('Card added', 'Success');
      this.showPaymentDetailsAddNewCardForm = false;
      this.planCardDetails = new CardDetailsForm();
      this.$emit('cardAdded', cardId);
    },
    onAddNewCard() {
      this.loading = true;
      EventHub.$emit('submit');
    },
    clearPaymentDetails() {
      this.planCardDetails = new CardDetailsForm();
      this.newPlanCardDetailsConfirmed = false;
    },
    getOrgId(): string {
      let orgId = '';
      const rawOrgId: string | (string | null)[] = this.$route.query.orgId;
      if (rawOrgId === undefined) {
        return '';
      }
      if (Array.isArray(rawOrgId)) {
        if (rawOrgId[0] !== null) {
          orgId = rawOrgId[0];
        }
      } else {
        if (rawOrgId !== null) {
          orgId = rawOrgId;
        }
      }
      return orgId;
    },
    getUserId(): string {
      let userId = '';
      const rawUserId: string | (string | null)[] = this.$route.query.userId;
      if (rawUserId === undefined) {
        return '';
      }
      if (Array.isArray(rawUserId)) {
        if (rawUserId[0] !== null) {
          userId = rawUserId[0];
        }
      } else {
        if (rawUserId !== null) {
          userId = rawUserId;
        }
      }
      return userId;
    }
  }
});

<template>
  <Modal @close="$emit('close')" v-if="show" noFooter>
    <slot slot="body">
      <h3 class="rm-mb--16">Are you sure?</h3>
      <p>Make selected card active for plan payments</p>
      <div style="display: flex; justify-content: flex-end">
        <ButtonField
          text="Confirm"
          @click="$emit('confirm')"
          size="medium"
          :loading="loading"
          ref="confirmButton"
          id="change-active-card__confirm"
        />
      </div>
    </slot>
  </Modal>
</template>
<script>
import { Modal, ButtonField } from 'rm-design';

export default {
  name: 'ChangeCardConfirmationModal',
  components: { Modal, ButtonField },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

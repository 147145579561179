






















import Vue from 'vue';
import { PullUpOverlay, ButtonField } from 'rm-design';
import ChangeOrganisation from './ChangeOrganisation.vue';

export default Vue.extend({
  name: 'PullUpChangeOrganisation',
  components: {
    PullUpOverlay,
    ButtonField,
    ChangeOrganisation
  },
  props: {
    isLogin: {
      type: Boolean,
      required: false,
      default: false
    },
    show: {
      type: Boolean,
      default: false,
      required: true
    }
  }
});













































































import Vue, { PropType } from 'vue';
import { ButtonField, Overlay, SelectField, TextField } from 'rm-design';
import BillingDetailsForm from './models/BillingDetailsForm';
import { getStripeBillingDetails, updateBillingDetails } from '@/api/stripe';
import { ICountry } from '@/api/organisations';
export default Vue.extend({
  name: 'BillingDetailsOverlay',
  components: {
    Overlay,
    ButtonField,
    TextField,
    SelectField
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    countries: {
      type: Array as PropType<ICountry[]>,
      default: () => []
    }
  },
  watch: {
    async show(shouldShow) {
      if (shouldShow) {
        this.form = new BillingDetailsForm();

        this.form.country.options = this.countries;
        // Pull details here
        await this.setBillingDetails();
      }
    }
  },

  data() {
    return {
      form: new BillingDetailsForm(),
      submitting: false
    };
  },
  methods: {
    async onSubmit() {
      if (this.form.validate()) {
        this.submitting = true;
        await updateBillingDetails({
          line1: this.form.addressLine1.value,
          line2: this.form.addressLine2.value,
          city: this.form.city.value,
          country: this.form.country.value?.id ?? '',
          postalCode: this.form.postCode.value,
          vatNumber: this.form.vatNumber.value
        });
        this.submitting = false;
        this.$snotify.success('Billing details updated', 'Success');
        this.$emit('close');
      }
    },
    async setBillingDetails() {
      const billingDetailsRes = await getStripeBillingDetails();

      if (billingDetailsRes && !billingDetailsRes.error) {
        if (billingDetailsRes.data) {
          this.form.addressLine1.value = billingDetailsRes.data.line1;
          this.form.addressLine2.value = billingDetailsRes.data.line2;
          this.form.city.value = billingDetailsRes.data.city;
          const chosenCountry = this.form.country.options?.find(
            x => x.id === billingDetailsRes.data?.country
          );
          if (chosenCountry) {
            this.form.country.value = chosenCountry;
          }
          this.form.postCode.value = billingDetailsRes.data.postalCode;
          this.form.vatNumber.value = billingDetailsRes.data.vatNumber;
        }
      }
    }
  }
});















































import Vue from 'vue';
import { getPaymentHistory, InvoicePaymentHistory } from '@/api/invoices';
import { Modal, ButtonField, TableView } from 'rm-design';
import { format } from 'date-fns';

export default Vue.extend({
  name: 'InvoicePaymentHistoryModal',
  components: { Modal, ButtonField, TableView },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      data: new Array<InvoicePaymentHistory>(),
      tableColumns: [
        { name: 'paymentDetails', label: 'Payment Details' },
        { name: 'paymentDate', label: 'Payment Date' },
        { name: 'status', label: 'Status' }
      ],
      format
    };
  },
  async mounted() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      this.loading = true;
      const res = await getPaymentHistory(this.id);
      this.loading = false;
      if (!res || res.error) {
        this.$snotify.error(res.message, 'Error');
        return;
      }
      this.data = res.data;
    }
  }
});


























import Vue from 'vue';
import { mapState } from 'vuex';
import { differenceInCalendarDays } from 'date-fns';
import axios from 'axios';
import router from './router';
import ReactivateSubscriptionModal from '@/components/ReactivateSubscriptionModal.vue';
import WarningBanner from '@/components/WarningBanner.vue';
import { getDefaultServer } from '@/api/organisations';
import DevConfig from '@/dev-config.js';
import { StripePlugin } from '@vue-stripe/vue-stripe';
import Privacy from '@/api/privacy';

export default Vue.extend({
  components: { ReactivateSubscriptionModal, WarningBanner },
  watch: {
    errorMessage(errorMessage) {
      if (errorMessage) {
        this.$snotify.error(errorMessage, 'Error');
      }
    }
  },
  data() {
    return {
      showReactivateSubscriptionModal: false,
      gettingConfig: true,
      subscriptionId: ''
    };
  },
  router,
  computed: {
    ...mapState([
      'systemCancellationDate',
      'errorMessage',
      'apiServerId',
      'futureSubscriptionExists',
      'annualPlanExceeded',
      'proRataDate'
    ]),
    cancellationWarning(): string {
      return `Your Rotify subscription has been cancelled, you have
      ${this.daysUntilCancellation} ${
        this.daysUntilCancellation === 1 ? 'day' : 'days'
      } remaining.`;
    },
    annualWarning(): string {
      return `You have exceeded the max user level on your annual plan, you will be charged for the additional staff,
       pro rated, on ${this.proRataDate} unless you return to your plan limit.`;
    },
    warningText(): string {
      if (this.annualPlanExceeded) {
        return this.annualWarning;
      } else {
        return this.cancellationWarning;
      }
    },
    daysUntilCancellation(): number {
      if (this.systemCancellationDate) {
        return differenceInCalendarDays(
          new Date(this.systemCancellationDate),
          new Date(Date.now())
        );
      }
      return 0;
    }
  },
  methods: {
    onReactivatedSubscription() {
      window.location.reload();
    },
    async setServerId() {
      // Call to the API to get default API Server
      const apiResponse = await getDefaultServer();

      if (!apiResponse.error) {
        const apiServerId = apiResponse.data;
        this.$store.commit('setApiServerId', apiServerId);
      }
    },
    openReactivateSubscriptionModal() {
      this.subscriptionId = this.$store.state.subscriptionId;
      this.showReactivateSubscriptionModal = true;
    }
  },
  created: async function() {
    let stripeKey = '';
    if (process.env.NODE_ENV === 'development') {
      this.$store.commit('setApiUrl', DevConfig.ApiUrl);
      this.$store.commit('setRotifyUrl', DevConfig.RotifyUrl);
      this.$store.commit('setUserGuidesUrl', DevConfig.UserGuidesUrl);
      stripeKey = DevConfig.StripePublicKey;
    } else {
      const configResponse = await axios.get('/static/config.json');
      if (configResponse && configResponse.data) {
        this.$store.commit('setApiUrl', configResponse.data.ApiUrl);
        this.$store.commit('setRotifyUrl', configResponse.data.RotifyUrl);
        this.$store.commit(
          'setUserGuidesUrl',
          configResponse.data.UserGuidesUrl
        );
        stripeKey = configResponse.data.StripePublicKey;
      }
    }

    Vue.use(StripePlugin, {
      pk: stripeKey
    });

    await this.setServerId();

    const policyUrls = await Privacy.get();
    if (policyUrls && policyUrls.data) {
      this.$store.commit(
        'setTermsAndConditionsUrl',
        policyUrls.data.termsAndConditionsUrl
      );
      this.$store.commit('setPrivacyPolicyUrl', policyUrls.data.privacyUrl);
    }
    this.gettingConfig = false;
  }
});

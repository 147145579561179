import { isValidEmail } from '@/utils/EmailValidator';

import {
  IPersonalDetails,
  IPersonalDetailsConfig,
  IPersonalDetailsAPIConfig,
  ITextField
} from '@/components/SignUp/signUpInterfaces';

export default class PersonalDetailsForm implements IPersonalDetails {
  constructor(config: IPersonalDetailsConfig = {}) {
    this.firstName = {
      value: (config && config.firstName) || '',
      errorMsg: ''
    };
    this.lastName = {
      value: (config && config.lastName) || '',
      errorMsg: ''
    };
    this.email = {
      value: (config && config.email) || '',
      errorMsg: ''
    };
    this.phoneNumber = {
      value: (config && config.phoneNumber) || '',
      errorMsg: ''
    };
  }

  firstName: ITextField;
  lastName: ITextField;
  email: ITextField;
  phoneNumber: ITextField;

  validate(): boolean {
    const requiredFields = [this.firstName, this.lastName, this.email];

    requiredFields.forEach(field => {
      field.errorMsg = field.value ? '' : 'Required';
    });

    this.email.errorMsg = isValidEmail(this.email.value);

    return requiredFields.every(field => field.errorMsg === '');
  }

  dataForApi(): IPersonalDetailsAPIConfig {
    const data: IPersonalDetailsAPIConfig = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      email: this.email.value,
      phoneNumber: this.phoneNumber.value
    };
    return data;
  }
}

























import Vue, { PropType } from 'vue';
import { Card, ButtonField } from 'rm-design';
import PlanLabelAndPrice from '@/components/Plan/PlanLabelAndPrice.vue';
import { IBillingData } from '@/components/BillingSummary/BillingSummary';
import { format } from 'date-fns';

export default Vue.extend({
  name: 'FuturePlanSummary',
  components: {
    Card,
    ButtonField,
    PlanLabelAndPrice
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    futurePlanSummaryInformation: {
      type: Object as PropType<IBillingData>
    },
    workforceLevelTierLimit: {
      type: Number,
      default: 0
    }
  },
  computed: {
    userCount(): number {
      return this.futurePlanSummaryInformation?.nextInvoice?.quantity || 0;
    },
    futurePaymentAmount(): number {
      return this.futurePlanSummaryInformation.value;
    },
    periodType(): string {
      return this.futurePlanSummaryInformation?.planName === 'Annually'
        ? 'Annually'
        : 'Monthly';
    },
    abbreviatedPeriodType(): string {
      return this.futurePlanSummaryInformation?.planName === 'Annually'
        ? 'annual'
        : 'month';
    },
    formattedPlanStartDate(): string {
      return this.futurePlanSummaryInformation.startDateTime
        ? format(
            new Date(this.futurePlanSummaryInformation.startDateTime),
            'do LLLL, yyyy'
          )
        : '';
    }
  }
});

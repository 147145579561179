export const isValidEmail = (email: string | null): string => {
  let errorMsg = '';
  if (!email) {
    errorMsg = 'Required';
  }
  if (email && !email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    errorMsg = 'Email address invalid';
  }
  return errorMsg;
};








































import Vue from 'vue';
import { Skeleton, SkeletonItem } from 'rm-design';

export default Vue.extend({
  name: 'SubscribeSkel',
  components: { Skeleton, SkeletonItem }
});

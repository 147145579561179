














































import Vue from 'vue';
import { Modal, ButtonField, RadioButton, TextField } from 'rm-design';
import { cancelSubscription } from '@/api/subscriptions';
export const CancelPeriods = {
  Immediate: 'Cancel immediately',
  EndOfSubPeriod: 'End of subscription period'
};
export default Vue.extend({
  name: 'CancelSmsPlanModal',
  components: { Modal, ButtonField, RadioButton, TextField },
  props: {
    show: {
      type: Boolean
    },
    smsSubId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      cancelPeriod: CancelPeriods.EndOfSubPeriod,
      CancelPeriods,
      password: '',
      cancelling: false
    };
  },
  methods: {
    closeModal() {
      this.password = '';
      this.cancelPeriod = CancelPeriods.EndOfSubPeriod;
      this.$emit('close');
    },
    async cancelSub() {
      this.cancelling = true;
      const res = await cancelSubscription({
        subscription: this.smsSubId,
        immediate: this.cancelPeriod === CancelPeriods.Immediate,
        password: this.password
      });
      this.cancelling = false;
      if (res && !res.error) {
        this.$snotify.success('Sms subscription cancelled', 'Success');
        this.$emit('cancelled');
        this.closeModal();
      } else {
        this.$snotify.error(
          res ? res.message : 'Failed to cancel sms subscription',
          'Error'
        );
      }
    }
  }
});

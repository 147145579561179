












































import Vue from 'vue';
import { Carousel, Slide } from 'vue-carousel';
import { ButtonField } from 'rm-design';
import PaymentCard from '@/components/PaymentDetails/PaymentCard.vue';
import { ICard } from '@/components/PaymentDetails/PaymentDetailsInterfaces';

export default Vue.extend({
  name: 'CardSelectionCarousel',
  components: {
    Carousel,
    Slide,
    ButtonField,
    PaymentCard
  },
  props: {
    cards: {
      type: Array as () => ICard[],
      required: true
    },
    includeAddCardButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onCardDelete(cardId) {
      this.$emit('deleteCard', cardId);
    },
    onSelectCard(card) {
      this.$emit('selectCard', card);
    }
  }
});

import router from '@/router';
import { selectOrg } from '@/api/auth';
import { getSubscriptions } from '@/api/subscriptions';
import { initialState } from '@/store/store';
import { getCards } from '@/api/cards';

export default {
  // eslint-disable-next-line max-params
  async setOrganisation(
    { commit, state, dispatch },
    organisation,
    goToRoot = true
  ) {
    const res = await selectOrg(organisation.id);
    if (!res.error) {
      const userData = {
        organisation,
        token: res.data
      };
      commit('setOrganisation', organisation);
      commit('setToken', userData.token);
      const subRes = await getSubscriptions();
      if (!subRes || subRes.error || !subRes.data || !subRes.data.system) {
        router.push('/subscribe');
      } else {
        if (goToRoot) {
          commit('setShouldCheckForLatestFailedPayment', true);
          router.currentRoute.path === '/'
            ? router.go()
            : router.push({ path: '/' });
        }
      }
    } else {
      const errorMessage = res.message || 'Could not select organisation';
      dispatch('displayErrorToast', errorMessage);
    }
  },
  async setPaymentCards({ commit }) {
    const res = await getCards();
    if (!res || res.error) {
      commit('setPaymentCards', []);
      return;
    }
    commit('setPaymentCards', res.data);
  },
  displayErrorToast({ commit }, errorMessage) {
    commit('setErrorMessage', errorMessage);

    setTimeout(() => {
      commit('resetErrorMessage');
    }, 5000);
  },
  logOut({ commit }) {
    commit('resetState', initialState);
    location.href = '/';
  }
};

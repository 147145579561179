import { httpHandler } from '@/utils/ApiResponseHandler';
export async function linkCard(paymentMethodId: string): Promise<any> {
  return httpHandler.post(`/cards/link/${paymentMethodId}`);
}

export async function getCards(): Promise<any> {
  return httpHandler.get('/cards');
}

export async function deleteCard(id: string): Promise<any> {
  return httpHandler.delete(`/cards/${id}`);
}
















































import Vue from 'vue';
import PasswordFormModel from '@/components/PasswordCheckList/models/PasswordForm';
import { TextField, ButtonField } from 'rm-design';
import PasswordFormIds from './constants/PasswordFormIds';
import PasswordCheckList from '@/components/PasswordCheckList/PasswordCheckList.vue';

export default Vue.extend({
  name: 'CreatePasswordForm',
  components: { TextField, ButtonField, PasswordCheckList },
  props: {
    form: {
      type: PasswordFormModel,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      passwordForm: this.form,
      PasswordFormIds
    };
  },
  methods: {
    onSubmit() {
      if (this.passwordForm.isValid) {
        this.$emit('create');
      } else {
        this.$snotify.error('Invalid password', 'Error');
      }
    }
  },
  mounted() {
    if (!this.passwordForm.password.value.length) {
      const passwordField: HTMLElement | null = this.$el.querySelector(
        `#${PasswordFormIds.Password}`
      );
      if (passwordField) {
        passwordField.focus();
      }
    }
  }
});










































import Vue from 'vue';
import { IFormStep } from './constants/SubscribeInterfaces';

export default Vue.extend({
  name: 'FormStepIndicator',
  props: {
    formSteps: {
      type: Array as () => IFormStep[],
      required: true
    },
    activeFormStep: {
      type: Number,
      required: true
    }
  }
});

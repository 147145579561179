<template>
  <Skeleton>
    <PlanLabelAndPriceLoadingSkeleton />
    <SkeletonItem>
      <div
        style="width: 200px;height: 24px; border-radius: 20px"
        class="rm-mb--12"
      ></div>
      <div
        style="width: 100%;height: 24px; border-radius: 20px"
        class="rm-mb--24"
      ></div>
    </SkeletonItem>
    <div style="display: flex">
      <SkeletonItem>
        <div
          style="width: 150px;height: 24px; border-radius: 20px"
          class="rm-mr--32"
        ></div>
      </SkeletonItem>
      <SkeletonItem>
        <div style="width: 150px;height: 24px; border-radius: 20px"></div>
      </SkeletonItem>
    </div>
    <PlanLabelAndPriceLoadingSkeleton class="rm-mt--32" />
    <SkeletonItem>
      <div
        style="width: 200px;height: 24px; border-radius: 20px"
        class="rm-mb--12"
      ></div>
      <div
        style="width: 100%;height: 24px; border-radius: 20px"
        class="rm-mb--24"
      ></div>
    </SkeletonItem>
  </Skeleton>
</template>

<script>
import { Skeleton, SkeletonItem } from 'rm-design';
import PlanLabelAndPriceLoadingSkeleton from './PlanLabelAndPriceLoadingSkeleton.vue';
export default {
  name: 'PlanSummaryLoadingSkeleton',
  components: { Skeleton, SkeletonItem, PlanLabelAndPriceLoadingSkeleton }
};
</script>

























import Vue from 'vue';
import { Modal, ButtonField } from 'rm-design';
import { format } from 'date-fns';
export default Vue.extend({
  name: 'UpdatePlanModal',
  components: { Modal, ButtonField },
  props: {
    billingCycleEndDate: {
      type: String,
      required: true
    },
    updatingPlan: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    formattedBillingCycleEndDate(): string {
      return format(new Date(this.billingCycleEndDate), 'dd/MM/yyyy');
    }
  },
  methods: {
    onConfirm() {
      if (!this.updatingPlan) {
        this.$emit('confirm');
      }
    }
  }
});





































































































































































import Vue, { PropType } from 'vue';
import { Modal, ButtonField, CheckBox } from 'rm-design';
import paymentFailedSvg from '@/assets/paymentFailed.svg';
import paymentSuccessSvg from '@/assets/paymentSuccess.svg';
import { ICard } from '@/components/PaymentDetails/PaymentDetailsInterfaces';
import PaymentCard from '@/components/PaymentDetails/PaymentCard.vue';
import Ids from './constants/retryFailedPaymentModalIds';
import modalViews from './constants/modalViews';
import CardSelectionCarousel from './CardSelectionCarousel.vue';
import AddNewCardModalStep from './AddNewCardModalStep.vue';
import { payInvoice } from '@/api/invoices';
import { updatePaymentCard } from '@/api/subscriptions';
import { IFailedPaymentModel } from '@/components/LatestPayments/interfaces';

export default Vue.extend({
  name: 'RetryFailedPaymentModal',
  components: {
    Modal,
    ButtonField,
    PaymentCard,
    CheckBox,
    CardSelectionCarousel,
    AddNewCardModalStep
  },
  props: {
    failedPaymentModel: {
      type: Object as PropType<IFailedPaymentModel>
    }
  },
  data() {
    return {
      Ids,
      paymentFailedSvg,
      paymentSuccessSvg,
      modalViews,
      modalView: modalViews.paymentFailed,
      previousModalView: '',
      paymentCard: {} as ICard,
      makeNewCardActiveCard: false,
      retryingPayment: false,
      makingPayment: false
    };
  },
  computed: {
    confirmScreenTitle(): string {
      if (this.modalView === modalViews.newCardAdded) {
        return 'New Card Added Success';
      } else if (this.modalView === modalViews.confirmPaymentCard) {
        return 'Confirmation';
      } else {
        return '';
      }
    },
    confirmScreenText(): string {
      if (this.modalView === modalViews.newCardAdded) {
        return 'You have successfully added your new card. Please continue to confirm your selected card and pay.';
      } else if (this.modalView === modalViews.confirmPaymentCard) {
        return 'Please confirm your selected card for your payments.';
      } else {
        return '';
      }
    },
    headerText(): string {
      if (this.failedPaymentModel.requiresAction) {
        return `Your Payment needs to be Authorised :(`;
      } else {
        return `Your Payment has Failed :(`;
      }
    },
    subText(): string {
      if (this.failedPaymentModel.requiresAction) {
        return `Please use the button below to authorise or select a new card to pay with`;
      } else {
        return `Please try to make your payment again or add new card details`;
      }
    },
    savedCards(): ICard[] {
      return this.$store.state.paymentCards;
    }
  },
  methods: {
    changeModalView(newView) {
      this.modalView = newView;
    },
    async onNewSavedCard(cardId: string) {
      const newCard = this.$store.state.paymentCards.find(x => x.id === cardId);
      if (newCard) {
        this.paymentCard = newCard;
      }
      this.modalView = modalViews.newCardAdded;
    },
    onSelectSavedCard(card: ICard) {
      this.paymentCard = card;
      this.modalView = modalViews.confirmPaymentCard;
    },
    async onMakePayment() {
      this.makingPayment = true;
      const response = await payInvoice({
        invoiceId: this.failedPaymentModel.invoiceId || '',
        cardId: this.paymentCard.id
      });

      if (response && !response.error) {
        if (this.makeNewCardActiveCard) {
          // Call to update card
          const response = await updatePaymentCard({
            subscriptionId: this.failedPaymentModel.subscriptionId || '',
            cardId: this.paymentCard.id
          });

          if (!response.error) {
            this.$snotify.success('Active card updated', 'Success');
          } else {
            this.$snotify.error('Failed to update active card', 'Failure');
          }
        }
        this.$emit('payment');
        this.modalView = modalViews.paymentSuccess;
      } else {
        this.$snotify.error(
          response ? response.message : 'Failed to pay invoice',
          'Error'
        );
      }

      this.makingPayment = false;
    },
    authorisePayment() {
      window.open(this.failedPaymentModel.hostedInvoiceUrl);
    },
    async retryOriginalPayment() {
      this.retryingPayment = true;
      const response = await payInvoice({
        invoiceId: this.failedPaymentModel.invoiceId || '',
        cardId: this.failedPaymentModel.paymentMethodId || ''
      });

      if (response && !response.error) {
        this.$emit('payment');
        this.modalView = modalViews.paymentSuccess;
      } else {
        this.$snotify.error(
          response ? response.message : 'Failed to pay invoice',
          'Error'
        );
      }
      this.retryingPayment = false;
    }
  }
});

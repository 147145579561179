<template>
  <Skeleton>
    <SkeletonItem>
      <div
        style="width: 200px;height: 32px;border-radius: 5px"
        class="rm-mb--8"
      ></div>
    </SkeletonItem>
    <SkeletonItem style="display: flex; justify-content: flex-end">
      <div
        style="width: 200px; height: 48px;border-radius:30px;"
        class="rm-mb--8"
      ></div>
    </SkeletonItem>
  </Skeleton>
</template>

<script>
import { Skeleton, SkeletonItem } from 'rm-design';
export default {
  name: 'PlanLabelAndPriceLoadingSkeleton',
  components: { Skeleton, SkeletonItem }
};
</script>
















































import Vue from 'vue';
import { CheckBox } from 'rm-design';
import CardDetailsForm from '@/components/PaymentDetails/models/CardDetailsForm';
import { CardDetailsIds } from '@/components/PaymentDetails/constants/CardDetailsIds';
import { updatePaymentCard } from '@/api/subscriptions';
import { setupIntent } from '@/api/stripe';
import EventHub from '@/eventHub';
import { mapActions } from 'vuex';

const style = {
  base: {
    color: 'black',
    fontFamily: 'Open Sans, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};
export enum ActiveCardOptions {
  Subscription = 'Rotify Subscription',
  SMS = 'SMS',
  FuturePlan = 'Future Plan'
}

export default Vue.extend({
  name: 'CardDetails',
  components: {
    CheckBox
  },
  props: {
    value: {
      type: CardDetailsForm,
      required: true
    },
    submitAttempted: {
      type: Boolean,
      default: false
    },
    showActiveCardCheckbox: {
      type: Boolean,
      default: false
    },
    activeCardOption: {
      type: String,
      default: ActiveCardOptions.Subscription
    }
  },
  computed: {
    form: {
      get(): CardDetailsForm {
        return this.value;
      },
      set(val: CardDetailsForm) {
        this.$emit('input', val);
      }
    },
    stripeElements(): any {
      return this.$stripe.elements();
    }
  },
  data() {
    return {
      CardDetailsIds: CardDetailsIds,
      cardNumber: null as any,
      cardExpiry: null as any,
      cardCvc: null as any,
      cardNumberError: '',
      cardExpiryError: '',
      cardCvcError: '',
      secret: ''
    };
  },
  mounted() {
    this.getSecret();
    this.cardNumber = this.stripeElements.create('cardNumber', {
      style,
      showIcon: true
    });
    this.cardNumber.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', { style });
    this.cardCvc.mount('#card-cvc');

    this.cardNumber.on('change', this.onStripeFieldChange);
    this.cardExpiry.on('change', this.onStripeFieldChange);
    this.cardCvc.on('change', this.onStripeFieldChange);
    EventHub.$on('submit', this.onClick);
  },
  beforeDestroy() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
    EventHub.$off('submit', this.onClick);
  },
  methods: {
    ...mapActions(['setPaymentCards']),
    async getSecret() {
      const res = await setupIntent();
      if (res && !res.error && res.data) {
        this.secret = res.data.secret;
      } else {
        this.$snotify.error(
          res ? res.message : 'Failed to setup payment',
          'Error'
        );
        this.$emit('addCardFailed');
      }
    },
    onStripeFieldChange(ev: any) {
      if (ev.elementType === 'cardNumber') {
        if (ev.error) {
          this.cardNumberError = ev.error.message;
        } else {
          this.cardNumberError = '';
        }
      } else if (ev.elementType === 'cardExpiry') {
        if (ev.error) {
          this.cardExpiryError = ev.error.message;
        } else {
          this.cardExpiryError = '';
        }
      } else if (ev.elementType === 'cardCvc') {
        if (ev.error) {
          this.cardCvcError = ev.error.message;
        } else {
          this.cardCvcError = '';
        }
      }
    },
    async onClick() {
      const cardSetupResponse = await this.$stripe.confirmCardSetup(
        this.secret,
        {
          payment_method: {
            type: 'card',
            card: this.cardNumber
          }
        }
      );
      if (cardSetupResponse.error) {
        this.$snotify.error(cardSetupResponse.error.message, 'Error');
        this.$emit('addCardFailed');
        return;
      }
      const cardId = cardSetupResponse.setupIntent.payment_method;
      if (this.form.makeActiveCard) {
        const updateCardRes = await updatePaymentCard({
          subscriptionId:
            this.activeCardOption === ActiveCardOptions.SMS
              ? this.$store.state.smsSubscriptionId
              : this.$store.state.subscriptionId,
          cardId
        });
        if (updateCardRes && !updateCardRes.error) {
          this.$emit('updatedActiveCard', cardId);
        } else {
          this.$snotify.error(
            updateCardRes
              ? updateCardRes.message
              : 'Failed to make card active',
            'Error'
          );
        }
      }
      await this.setPaymentCards();
      this.$emit('addedCard', cardId);
    }
  }
});






































































import Vue, { PropType } from 'vue';
import ProgressBar from '@/components/ProgressBar.vue';
import { Card, ButtonField } from 'rm-design';
import { format } from 'date-fns';
import { getProjectInvoice, getUpperBandLimit } from '@/api/stripe';

export default Vue.extend({
  name: 'NextPaymentCard',
  components: { ProgressBar, Card, ButtonField },
  props: {
    userCount: {
      type: Number,
      default: 0
    },
    cost: {
      type: Number,
      default: 0
    },
    paymentDate: {
      type: Date as PropType<Date>,
      required: false,
      default: null
    },
    billProgressPercentage: {
      type: Number,
      default: 0
    },
    usersTakenPercentage: {
      type: Number,
      default: 0
    },
    daysTilNextPayment: {
      type: Number,
      default: 0
    },
    workforceNumber: {
      type: Number,
      default: 0
    },
    availableUserCount: {
      type: Number,
      default: 0
    },
    periodType: {
      type: String,
      default: 'month'
    },
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    futureSubscriptionExists(): boolean {
      return this.$store.state.futureSubscriptionExists;
    },
    availableUserText(): string {
      if (this.availableUserCount >= 0) {
        return `${this.availableUserCount} users available`;
      } else {
        return `You are above your plan limit`;
      }
    },
    formattedPaymentDate(): string {
      if (this.paymentDate) {
        return format(this.paymentDate, 'do LLLL, yyyy');
      }
      return '';
    },
    displayCost(): string {
      return this.cost ? this.cost.toFixed(2) : '0.00';
    }
  },
  data() {
    return {
      upperBandLimit: 0 as number
    };
  },
  async mounted() {
    const bandResponse = await getUpperBandLimit(this.workforceNumber);
    this.upperBandLimit = bandResponse.data ?? 0;
  }
});

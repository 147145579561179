








































import Vue from 'vue';
import { ButtonField, RadioButton } from 'rm-design';
import { ISystemPlan } from '@/api/stripe';

export default Vue.extend({
  name: 'SelectFeatures',
  components: { ButtonField, RadioButton },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    systemPlans: {
      type: Array as () => ISystemPlan[],
      default: () => []
    },
    additionalPlans: {
      type: Array as () => ISystemPlan[],
      default: () => []
    },
    defaultPlan: {
      type: String,
      default: 'Standard'
    },
    paymentFrequency: {
      type: Object
    }
  },
  mounted() {
    this.systemPlanName = this.defaultPlan;
  },
  data() {
    return {
      systemPlanName: ''
    };
  },
  computed: {
    chosenSystemPlan(): ISystemPlan | undefined {
      return this.systemPlans.find(x => x.name === this.systemPlanName);
    }
  },
  methods: {
    async onPlanChange(planId: string) {
      this.$emit('systemPlanChange', planId);
    },
    async onAdditionalPlanAdded(planId: string) {
      this.$emit('additionalPlanAdded', planId);
    },
    getPlanDescription(plan) {
      const price = plan.prices.find(
        x => x.name === this.paymentFrequency.label
      );
      return plan.active ? price.cost : 'Coming Soon!';
    }
  }
});

import {
  IPasswordForm,
  IPasswordAPIConfig
} from '@/components/SignUp/signUpInterfaces';

export const SpecialCharsString = '#?!@$%^&*-';
export const MinChars = 8;
export const MaxChars = 16;

export default class PasswordForm implements IPasswordForm {
  constructor() {
    this.password = { value: '' };
    this.confirmPassword = { value: '' };
  }
  password: { value: string };
  confirmPassword: { value: string };
  get minCharsReached(): boolean {
    return this.password.value.length >= MinChars;
  }

  get isAtOrBelowMaxChars(): boolean {
    return this.password.value.length <= MaxChars;
  }
  isLetter(ch: string): boolean {
    return ch.toUpperCase() !== ch.toLowerCase();
  }

  get containsOneLowerCaseChar(): boolean {
    return this.password.value
      .split('')
      .some(
        char =>
          Number.isNaN(Number(char)) &&
          this.isLetter(char) &&
          char === char.toLowerCase()
      );
  }

  get containsOneUpperCaseChar(): boolean {
    return this.password.value
      .split('')
      .some(
        char =>
          Number.isNaN(Number(char)) &&
          this.isLetter(char) &&
          char === char.toUpperCase()
      );
  }

  get containsOneNumber(): boolean {
    const numbers = '1234567890';
    const splitNumbers = numbers.split('');
    return this.password.value
      .split('')
      .some(char => splitNumbers.includes(char));
  }

  get containsSpecialChar(): boolean {
    const splitSpecialChars = SpecialCharsString.split('');
    return this.password.value
      .split('')
      .some(char => splitSpecialChars.includes(char));
  }

  get passwordsMatch(): boolean {
    return (
      this.password.value.length > 0 &&
      this.password.value === this.confirmPassword.value
    );
  }

  get isValid(): boolean {
    return (
      this.minCharsReached &&
      this.isAtOrBelowMaxChars &&
      this.containsOneLowerCaseChar &&
      this.containsOneUpperCaseChar &&
      this.containsOneNumber &&
      this.containsSpecialChar &&
      this.passwordsMatch
    );
  }

  dataForApi(): IPasswordAPIConfig {
    const data: IPasswordAPIConfig = {
      password: this.password.value,
      confirmPassword: this.confirmPassword.value
    };
    return data;
  }
}



















































































import Vue from 'vue';
import { Modal, ButtonField } from 'rm-design';
import { ICard } from '@/components/PaymentDetails/PaymentDetailsInterfaces';
import CardSelectionCarousel from './CardSelectionCarousel.vue';
import AddNewCardModalStep from './AddNewCardModalStep.vue';
import PaymentCard from '@/components/PaymentDetails/PaymentCard.vue';
import { format, addMonths } from 'date-fns';
import { getPlans } from '@/api/stripe';
const modalViews = {
  selectCard: 'Select Card',
  addNewCard: 'Add New Card',
  confirmation: 'Set Up SMS Confirmation'
};
import { createSubscription, ISubscriptionArg } from '@/api/subscriptions';

export default Vue.extend({
  name: 'EnableSmsPaymentModal',
  components: {
    Modal,
    ButtonField,
    CardSelectionCarousel,
    AddNewCardModalStep,
    PaymentCard
  },
  data() {
    return {
      modalViews,
      modalView: modalViews.selectCard,
      selectedCard: {} as ICard,
      enabling: false
    };
  },
  computed: {
    dateOneMonthFromNow(): string {
      const date = addMonths(new Date(Date.now()), 1);
      return format(date, 'dd/MM/yyyy');
    },
    savedCards(): ICard[] {
      return this.$store.state.paymentCards;
    }
  },
  methods: {
    onSelectSavedCard(card: ICard) {
      this.selectedCard = card;
      this.modalView = this.modalViews.confirmation;
    },
    async onNewSavedCard(cardId: string) {
      const newCard = this.$store.state.paymentCards.find(x => x.id === cardId);
      if (newCard) {
        this.onSelectSavedCard(newCard);
      }
    },
    async onSubscribeToSms() {
      this.enabling = true;
      const smsPlansRes = await getPlans();
      if (!smsPlansRes || smsPlansRes.error || !smsPlansRes.data) {
        this.enabling = false;
        this.$snotify.error(
          smsPlansRes ? smsPlansRes.message : 'Failed to get sms plans',
          'Error'
        );
        return;
      }
      const createSubArg: ISubscriptionArg = {
        planId: smsPlansRes.data.smsPlans[0].id,
        cardId: this.selectedCard.id
      };
      const createSubResponse = await createSubscription(createSubArg);
      this.enabling = false;
      if (!createSubResponse || createSubResponse.error) {
        this.$snotify.error(
          createSubResponse
            ? createSubResponse.message
            : 'Failed to create subscription',
          'Error'
        );
        return;
      }
      this.$emit('smsEnabled');
      this.$snotify.success('Sms enabled', 'Success');
      this.$emit('close');
    }
  }
});

import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Register from '../views/Register.vue';
import UpdatePlan from '../views/UpdatePlan.vue';
import Login from '../views/Login.vue';
import Subscribe from '../views/Subscribe.vue';
import { store } from '@/store/store';
import Verify from '../views/Verify.vue';
import EmptyOrganisationsState from '../views/EmptyOrganisationsState.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/update-plan',
    name: 'UpdatePlan',
    component: UpdatePlan,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: Subscribe,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/verify',
    name: 'Verify',
    component: Verify,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/empty-organisations',
    name: 'EmptyOrganisationsState',
    component: EmptyOrganisationsState,
    meta: {
      requiresAuth: false
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// eslint-disable-next-line max-params
export function beforeEach(to, from, next, store): void {
  if (to.meta.requiresAuth && !store.state.token) {
    return next('/login');
  }
  next();
}

// eslint-disable-next-line max-params
router.beforeEach((to, from, next) => beforeEach(to, from, next, store));
export default router;

import { httpHandler } from '@/utils/ApiResponseHandler';
import { IApiResponse } from '@/interfaces/common';
import { ISubscriptionArg } from './subscriptions';

export async function getProjectInvoice(
  invoiceDetails: ISubscriptionArg
): Promise<IApiResponse<{ cost: number; costWithVat: number }>> {
  return httpHandler.post(`/stripe/projectedinvoice`, invoiceDetails);
}

export async function getUpperBandLimit(
  workForce: number
): Promise<IApiResponse<number>> {
  return httpHandler.get(`/stripe/workforce/${workForce}`);
}

export interface ISystemPlan {
  id: string;
  name: string;
  tiered: boolean;
  active: boolean;
  features: Array<string>;
  costPerUnit: number;
  tiers?: Array<{
    maxPersonnel: number;
    price: number;
  }>;
}

export interface IBillingDetails {
  line1: string;
  line2: string;
  city: string;
  country: string;
  postalCode: string;
  vatNumber: string;
}

export interface IPlans {
  systemPlans: Array<ISystemPlan>;
  additionalPlans: Array<ISystemPlan>;
  smsPlans: Array<ISystemPlan>;
}
export async function getPlans(): Promise<IApiResponse<IPlans>> {
  return httpHandler.get('/stripe/plans');
}

export async function setupIntent(): Promise<IApiResponse<{ secret: string }>> {
  return httpHandler.post('/stripe/setupintent');
}

export async function getStripeBillingDetails(): Promise<
  IApiResponse<IBillingDetails>
> {
  return httpHandler.get('/stripe/billingdetails');
}

export async function updateBillingDetails(
  config: IBillingDetails
): Promise<IApiResponse<string>> {
  return httpHandler.post('/stripe/billingdetails', config);
}

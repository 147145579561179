

































































































































































import Vue from 'vue';
import { TextField, ButtonField } from 'rm-design';
import LoginIDs from '@/components/Login/constants/LoginIDs.js';
import SlideshowSplitLayout from '@/components/SlideshowSplitLayout/SlideshowSplitLayout.vue';
import ChangeOrganisationModal from '@/components/ChangeOrganisation/ChangeOrganisationModal.vue';
import PullUpChangeOrganisation from '@/components/ChangeOrganisation/PullUpChangeOrganisation.vue';
import DeviceBreakpoints from '@/constants/DeviceBreakPoints';
import { mapActions } from 'vuex';
import {
  authenticate,
  forgottenPassword,
  IAuthenticateArg,
  IForgottenPasswordArg
} from '@/api/user';

export default Vue.extend({
  name: 'Login',
  components: {
    TextField,
    ButtonField,
    SlideshowSplitLayout,
    ChangeOrganisationModal,
    PullUpChangeOrganisation
  },
  data() {
    return {
      LoginIDs,
      formLogin: {
        errorMsg: '',
        email: {
          value: '',
          errorMsg: ''
        },
        password: {
          value: '',
          errorMsg: ''
        }
      },
      showChangeOrgModal: false,
      formForgotten: {
        valid: true,
        processed: false,
        errorMsg: '',
        email: {
          value: '',
          validate: e => {
            e.errorMsg = '';
            if (!e.value.length)
              e.errorMsg = 'Please enter your account email!';
          },
          errorMsg: ''
        }
      },
      logonToken: '',
      organisation: null,
      organisations: [],
      loading: false,
      rememberMe: true,
      forgotten: false,
      frmLinkUserVisible: false,
      isMobileView: false
    };
  },
  watch: {
    forgotten() {
      this.formForgotten.errorMsg = '';
    }
  },
  created() {
    window.addEventListener('keyup', this.onKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyUp);
    window.removeEventListener('resize', this.onWindowResize);
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize);
    this.onWindowResize();
  },
  methods: {
    ...mapActions(['setOrganisation']),
    onWindowResize() {
      this.isMobileView = window.innerWidth <= DeviceBreakpoints.Small;
    },
    openPrivacyPolicy() {
      window.open(this.$store.state.privacyPolicyUrl);
    },
    openTermsAndConditions() {
      window.open(this.$store.state.termsAndConditionsUrl);
    },
    onKeyUp(e) {
      if (e.keyCode === 13 && !this.forgotten) {
        this.onLogin();
      } else if (e.keyCode === 13 && this.forgotten) {
        this.onForgotten();
      }
    },

    async onLogin() {
      this.loading = true;
      this.formLogin.errorMsg = '';

      const { email, password } = this.formLogin;

      email.errorMsg = email.value ? '' : 'Required';
      password.errorMsg = password.value ? '' : 'Required';

      if (email.errorMsg || password.errorMsg) {
        this.loading = false;
        return;
      }
      const arg: IAuthenticateArg = {
        email: email.value,
        password: password.value,
        apiServerId: this.$store.state.apiServerId
      };
      try {
        const res = await authenticate(arg);
        if (!res || res.error) {
          this.formLogin.errorMsg = res?.message
            ? res.message
            : 'Error authenticating';
          this.$snotify.error(
            res?.message ? res.message : 'Error authenticating',
            'Error'
          );
          return;
        }
        if (res.data?.userLoginValidation?.errors?.length) {
          this.formLogin.errorMsg =
            res.data.userLoginValidation.errors[0].message;
          this.$snotify.error(
            res?.message ? res.message : 'Error authenticating',
            'Error'
          );
          return;
        }
        this.logonToken = res.data.tokens.authToken;
        this.$store.commit('setUserEmail', this.formLogin.email.value);
        this.setCurrentUserId(res.data.currentUserId);
        this.$store.commit('setToken', this.logonToken);
        this.$store.commit('setRotifyUrl', res.data.rotifyUrl);
        if (
          res.data.userLoginValidation &&
          res.data.userLoginValidation.requiresVerification
        ) {
          this.$router.push('/verify');
          return;
        }

        this.$store.commit('setShouldCheckForLatestFailedPayment', true);
        this.$store.commit('setUserOrganisations', res.data.organisations);
        const organisations = res.data.organisations.map(x => ({
          ...x,
          label: x.name
        }));

        if (organisations.length === 0) {
          this.$router.push('/empty-organisations');
          return;
        } else if (organisations.length === 1) {
          await this.setOrganisation(organisations[0]);
          this.$store.commit('setCurrentUserId', res.data.currentUserId);
        } else if (organisations.length > 1) {
          this.showChangeOrgModal = true;
        }
      } catch (exception) {
        this.$snotify.error(exception, 'Error');
      } finally {
        this.loading = false;
      }
    },
    setRefreshToken(refreshToken) {
      this.$store.commit('setRefreshToken', refreshToken);
    },
    setCurrentUserId(currentUserId) {
      this.$store.commit('setCurrentUserId', currentUserId);
    },

    async onForgotten() {
      this.loading = true;
      try {
        this.formForgotten.valid = true;
        const validate = form => {
          for (const key in form) {
            if (form[key].validate) {
              form[key].validate(form[key]);
              if (form[key].errorMsg) form.valid = false;
            }
          }
        };
        validate(this.formForgotten);

        if (!this.formForgotten.valid) {
          this.loading = false;
          return;
        }

        const arg: IForgottenPasswordArg = {
          email: this.formForgotten.email.value,
          apiServerId: this.$store.state.apiServerId
        };
        const res = await forgottenPassword(arg);
        if (!res.error) {
          this.formForgotten.processed = true;
        } else {
          this.formForgotten.errorMsg = res.message;
        }
      } catch (exception) {
        console.error(exception.message);
      }
      this.loading = false;
    }
  }
});

import { differenceInCalendarDays } from 'date-fns';

interface IPeriod {
  start: Date;
  end: Date;
}

export const getDurationPassedInPercentage = (
  period: IPeriod,
  progressDate: Date
): number => {
  const totalDurationInDays = differenceInCalendarDays(period.end, period.start);
  const durationPassedInDays = differenceInCalendarDays(progressDate, period.start);

  const percentageVal = Math.round(
    (durationPassedInDays / totalDurationInDays) * 100
  );
  return percentageVal > 100 ? 100 : percentageVal;
};

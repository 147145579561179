



































































































































































































import Vue from 'vue';
import { ICard } from '@/components/PaymentDetails/PaymentDetailsInterfaces';
import { Card, ButtonField, RadioButton } from 'rm-design';
import NextPaymentCard from '@/components/Plan/NextPaymentCard.vue';
import LastPaymentCard from '@/components/Plan/LastPaymentCard.vue';
import SavedCards from '@/components/SavedCards/SavedCards.vue';
import AddCardOverlay from '@/components/AddCardOverlay.vue';
import UpdatePlanModal from '@/components/Plan/UpdatePlanModal.vue';
import RetryFailedPaymentModal from '@/components/PaymentModal/RetryFailedPaymentModal.vue';
import {
  getSubscriptions,
  ISubscriptionsSystem,
  updateSubscription,
  resumeSubscription,
  updatePaymentCard,
  ISubscriptionsDataResponse
} from '@/api/subscriptions';
import { getPlans, ISystemPlan, getProjectInvoice } from '@/api/stripe';
import PaymentCardSkel from '@/components/Plan/PaymentCardSkel.vue';
import { differenceInCalendarDays } from 'date-fns';
import { deleteCard } from '@/api/cards';
import UpdateBillingPeriodSkel from '@/components/Plan/UpdateBillingPeriodSkel.vue';
import {
  IPaymentModel,
  IFailedPaymentModel
} from '@/components/LatestPayments/interfaces';
import { ILatestPaymentsMetaData } from '@/components/LatestPayments/LatestPayments.vue';
import { startOfMonth, addMonths, addWeeks, format } from 'date-fns';
import { IGetInvoicesArg, getInvoices } from '@/api/invoices';
import ChangeCardConfirmationModal from '@/components/ChangeCardConfirmationModal.vue';
import DangerModal from '@/components/DangerModal.vue';
import { mapActions } from 'vuex';
const BillingPeriodOptions = {
  Annually: { label: 'Annually' },
  Monthly: { label: 'Monthly' }
};

export default Vue.extend({
  name: 'UpdatePlan',
  components: {
    Card,
    ButtonField,
    RadioButton,
    NextPaymentCard,
    LastPaymentCard,
    SavedCards,
    AddCardOverlay,
    UpdatePlanModal,
    UpdateBillingPeriodSkel,
    PaymentCardSkel,
    RetryFailedPaymentModal,
    ChangeCardConfirmationModal,
    DangerModal
  },
  data() {
    return {
      addCardOverlayIsOpen: false,
      updatePlanModalIsOpen: false,
      billingPeriod: '',
      BillingPeriodOptions,
      activeCardId: '',
      savedCards: [] as ICard[],
      systemSubscription: {} as ISubscriptionsSystem | undefined,
      systemPlans: [] as ISystemPlan[],
      updatingSub: false,
      gettingCards: false,
      gettingSubs: true,
      gettingLatestPayments: false,
      latestPaymentData: new Array<IPaymentModel>(),
      latestPaymentsMetaData: {
        dateRange: [
          startOfMonth(addMonths(new Date(Date.now()), -3)),
          new Date(Date.now())
        ],
        filters: {
          subscriptionType: null,
          status: null
        },
        pager: {
          page: 1,
          pageSize: 20,
          total: 0
        }
      } as ILatestPaymentsMetaData,
      failedPaymentModel: {} as IFailedPaymentModel,
      showRetryPaymentModal: false,
      fetchingRetryPaymentData: false,
      costOfUpdatedPlan: '0',
      changingActiveCardId: '',
      showChangeActiveCardConfirmationModal: false,
      billingData: null as ISubscriptionsDataResponse | null,
      changingActiveCard: false,
      deleteCardConfirmationModalIsOpen: false,
      deletingCard: false,
      cardIdToDelete: ''
    };
  },
  computed: {
    daysTilNextPayment(): number {
      if (!this.systemSubscription || !this.systemSubscription.nextInvoice) {
        return 0;
      }
      const today = new Date();
      const nextPayment = new Date(
        this.systemSubscription.nextInvoice.periodStartDateTime
      );
      return differenceInCalendarDays(nextPayment, today);
    },
    shouldShowUpdatePlanButton(): boolean {
      let shouldShow = false;
      if (!this.systemSubscription) {
        return shouldShow;
      }
      if (this.billingPeriod === BillingPeriodOptions.Annually.label) {
        if (
          this.systemSubscription.planName ===
          BillingPeriodOptions.Monthly.label
        ) {
          shouldShow = true;
        }
      } else if (this.billingPeriod === BillingPeriodOptions.Monthly.label) {
        if (
          this.systemSubscription.planName ===
          BillingPeriodOptions.Annually.label
        ) {
          shouldShow = true;
        }
      }
      return shouldShow;
    },
    billProgressPercentage(): number {
      if (!this.systemSubscription || !this.systemSubscription.nextInvoice) {
        return 0;
      }

      const startDate = new Date(
        this.systemSubscription.nextInvoice.periodStartDateTime
      );
      const endDate = new Date(
        this.systemSubscription.nextInvoice.periodEndDateTime
      );
      const today = new Date();
      const totalDaysInPeriod = differenceInCalendarDays(endDate, startDate);
      const totalDaysPassed = differenceInCalendarDays(startDate, today);
      return (totalDaysPassed / totalDaysInPeriod) * 100;
    },
    usersTakenPercentage(): number {
      if (!this.systemSubscription || !this.systemSubscription.nextInvoice) {
        return 0;
      }
      return (
        (this.systemSubscription.workforceLevel /
          this.systemSubscription.nextInvoice.quantity) *
        100
      );
    }
  },
  async mounted() {
    this.init();
  },
  methods: {
    ...mapActions(['setPaymentCards']),
    async init() {
      this.getPlans();
      this.gettingCards = true;
      await this.setPaymentCards();
      await this.getSubs();
      // sub data tells us which card is active,
      // so prolong gettingCards=true state on saved cards til then
      this.gettingCards = false;
      await this.getCostOfUpdatedPlan();
    },
    async getCostOfUpdatedPlan() {
      if (!this.systemSubscription) {
        return;
      }
      const data = {
        workforceLevel: this.systemSubscription.tierLimit,
        monthly:
          this.systemSubscription.planName ===
          BillingPeriodOptions.Annually.label,
        additionalPlans: [],
        planId: this.systemPlans[0].id,
        cardId: ''
      };
      const res = await getProjectInvoice(data);

      if (res && !res.error && res.data) {
        this.costOfUpdatedPlan = res.data.costWithVat.toFixed(2);
      }
    },
    async onReactivateSubscription() {
      if (!this.systemSubscription) {
        return;
      }
      const res = await resumeSubscription(
        this.systemSubscription.subscriptionId
      );
      if (!res || !res.error) {
        this.$snotify.error(
          res ? res.message : 'Failed to resume subscription',
          'Error'
        );
        return;
      }
      this.init();
    },
    async getPlans() {
      const systemPlansRes = await getPlans();
      if (systemPlansRes && !systemPlansRes.error && systemPlansRes.data) {
        this.systemPlans = systemPlansRes.data.systemPlans;
      }
    },
    async getSubs() {
      this.gettingSubs = true;
      const res = await getSubscriptions();
      this.gettingSubs = false;
      if (!res || res.error) {
        this.$snotify.error('Failed to get subscription', 'Error');
        return;
      }
      if (res.data?.future) {
        this.$router.push('/');
        this.$snotify.error(
          'Cannot upgrade a plan with a future upgrade already scheduled',
          'Error'
        );
        return;
      }
      this.billingData = res.data || null;
      const activeCardId = res.data?.system?.activeCard?.id;
      if (activeCardId) {
        this.activeCardId = activeCardId;
      }
      const systemSub = res.data?.system;
      if (systemSub) {
        this.systemSubscription = systemSub;
        this.billingPeriod = this.systemSubscription.planName;
      }
    },
    async onUpdatePlanConfirm() {
      if (!this.systemSubscription) {
        return;
      }
      let planId = '';
      const systemPlan = this.systemPlans[0];
      if (systemPlan) {
        planId = systemPlan.id;
      }
      this.updatingSub = true;
      const res = await updateSubscription({
        planId,
        monthly:
          this.systemSubscription.planName ===
          BillingPeriodOptions.Annually.label,
        cardId: this.systemSubscription.activeCard.id,
        workforceLevel: this.systemSubscription.workforceLevel
      });

      this.updatingSub = false;
      if (res && !res.error) {
        this.updatePlanModalIsOpen = false;
        this.$snotify.success('Updated plan', 'Success');
        this.$router.push('/');
      } else {
        this.$snotify.error(res.message, 'Failure');
      }
    },
    async getLatestPayments() {
      const now = new Date();
      let fromDate = now;
      let toDate = addWeeks(now, 4);

      if (this.latestPaymentsMetaData.dateRange[0]) {
        fromDate = this.latestPaymentsMetaData.dateRange[0];
      }
      if (this.latestPaymentsMetaData.dateRange[1]) {
        toDate = this.latestPaymentsMetaData.dateRange[1];
      }
      const arg: IGetInvoicesArg = {
        fromDate: format(fromDate, 'yyyy-MM-dd'),
        toDate: format(toDate, 'yyy-MM-dd 23:59:59'),
        pageSize: this.latestPaymentsMetaData.pager.pageSize,
        pageNumber: this.latestPaymentsMetaData.pager.page
      };
      if (this.latestPaymentsMetaData.filters.subscriptionType) {
        arg.subscriptionType = this.latestPaymentsMetaData.filters.subscriptionType.label;
      }
      if (this.latestPaymentsMetaData.filters.status) {
        arg.status = this.latestPaymentsMetaData.filters.status.id as string;
      }

      const response = await getInvoices(arg);

      if (!response || response.error) {
        this.$snotify.error(response ? response.message : '', 'Error');
        return;
      }

      if (response.data) {
        this.latestPaymentData = response.data.invoices;
        this.latestPaymentsMetaData.pager.total = response.data.total;
      }
    },
    async onBeginRetryPayment(paymentId: string) {
      this.fetchingRetryPaymentData = true;
      await this.getLatestPayments();
      this.fetchingRetryPaymentData = false;
      const invoice = this.latestPaymentData.find(x => x.id === paymentId);
      if (invoice) {
        this.failedPaymentModel = {
          invoiceId: invoice.id,
          paymentMethodId: invoice.paymentMethodId,
          subscriptionId: invoice.subscriptionId,
          requiresAction: invoice.requiresAction,
          hostedInvoiceUrl: invoice.hostedInvoiceUrl
        };
        this.showRetryPaymentModal = true;
      } else {
        this.$snotify.error('Cannot find invoice', 'Error');
      }
    },
    onCloseRetryPaymentModal() {
      this.showRetryPaymentModal = false;
      this.failedPaymentModel = {} as IFailedPaymentModel;
    },
    async onRetryPaymentSuccess() {
      this.gettingLatestPayments = true;
      await this.getLatestPayments();
      await this.getSubs();
      this.gettingLatestPayments = false;
    },
    onBeginChangeActiveCard(cardId) {
      this.changingActiveCardId = cardId;
      this.showChangeActiveCardConfirmationModal = true;
    },
    onCloseChangeActiveCardConfirmationModal() {
      this.changingActiveCardId = '';
      this.showChangeActiveCardConfirmationModal = false;
    },
    async onChangeActiveCard(cardId: string): Promise<void> {
      this.changingActiveCard = true;
      if (!this.billingData?.system) {
        this.$snotify.error('Error updating active card', 'Error');
        this.changingActiveCard = false;
        return;
      }
      let subscriptionId = '';
      subscriptionId = this.billingData.system.subscriptionId;
      const res = await updatePaymentCard({ subscriptionId, cardId });
      if (!res || res.error) {
        this.$snotify.error('Failed to update active card', 'Failure');
        this.changingActiveCard = false;
        return;
      }
      this.onCloseChangeActiveCardConfirmationModal();
      this.changingActiveCard = false;
      this.$snotify.success('Active card updated', 'Success');
      await this.getSubs();
    },
    onBeginDeleteCard(cardId: string) {
      this.cardIdToDelete = cardId;
      this.deleteCardConfirmationModalIsOpen = true;
    },
    async onDeleteCard(): Promise<void> {
      if (!this.cardIdToDelete.length) {
        return;
      }
      this.deletingCard = true;
      const res = await deleteCard(this.cardIdToDelete);
      this.deletingCard = false;
      this.deleteCardConfirmationModalIsOpen = false;
      if (!res || res.error) {
        this.$snotify.error(res?.message || 'Failed to delete card', 'Error');
        return;
      }
      this.savedCards = this.savedCards.filter(
        x => x.id !== this.cardIdToDelete
      );
      this.cardIdToDelete = '';
      this.$snotify.success('Card deleted', 'Success');
    }
  }
});



































































import Vue from 'vue';
import { CircularGraph, Skeleton, SkeletonItem, ButtonField } from 'rm-design';

export default Vue.extend({
  name: 'BillingInfoCircle',
  components: { CircularGraph, Skeleton, SkeletonItem, ButtonField },
  methods: {
    onSignUp() {
      this.$router.push('/subscribe');
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    bodyText: {
      type: String,
      default: 'No details to show'
    },
    footerText: {
      type: String,
      default: ''
    },
    currentVal: {
      type: Number,
      default: 1
    },
    maxVal: {
      type: Number,
      default: 1
    },
    showProgressMarker: {
      type: Boolean,
      default: false
    },
    activeColour: {
      type: String,
      default: 'black'
    },
    inactiveColour: {
      type: String,
      default: '#ffffff'
    },
    loading: {
      type: Boolean,
      default: false
    },
    showSignUp: {
      type: Boolean,
      default: false
    }
  }
});

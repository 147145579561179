



























import Vue from 'vue';
import { Modal, RadioButton, ButtonField } from 'rm-design';

const radioOptions = [
  { label: 'Yes, continue my current subscription', value: true },
  { label: 'No, do not continue my current subscription', value: false }
];
export default Vue.extend({
  name: 'CancelFutureSubscriptionModal',
  components: {
    Modal,
    RadioButton,
    ButtonField
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      radioOptions,
      continueWithCurrentSubscription: radioOptions[1].label
    };
  },
  methods: {
    onConfirmCancellation() {
      const selectedOption = radioOptions.find(
        option => option.label === this.continueWithCurrentSubscription
      );

      if (selectedOption) {
        this.$emit('onCancelFutureSubscription', selectedOption.value);
      }
    }
  }
});

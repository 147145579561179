




















































































import Vue, { PropType } from 'vue';
import { TableView, ButtonField, ToolTip } from 'rm-design';
import { format } from 'date-fns';
import { IPaymentModel } from './interfaces';
import paymentStatusOptions from './constants/paymentStatusTypes';

export default Vue.extend({
  name: 'PaymentsTable',
  components: { TableView, ButtonField, ToolTip },
  props: {
    data: {
      type: Array as PropType<IPaymentModel[]>
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [
        { name: 'customerEmail', label: 'Invoice Email', width: '120px' },
        { name: 'createdDateTime', label: 'Invoice Date', width: '120px' },
        { name: 'paymentDate', label: 'Payment Date', width: '120px' },
        { name: 'planName', label: 'Plan', width: '100px' },
        { name: 'cost', label: 'Cost', width: '70px' },
        { name: 'paymentDetails', label: 'Payment Details', width: '140px' },
        { name: 'status', label: 'Payment Status', width: '150px' },
        { name: 'invoiceURL', label: '', width: '40px' }
      ]
    };
  },
  methods: {
    getStatusLabel(statusName: string): string {
      let statusLabel = '';
      const status = paymentStatusOptions.find(x => x.id === statusName);
      if (status) {
        statusLabel = status.label;
      } else {
        statusLabel = statusName;
      }
      return statusLabel;
    },
    downloadInvoicePdf(url: string) {
      window.open(url, '_blank');
    },
    formatDate(date: string): string {
      return format(new Date(date), 'dd/MM/yyyy');
    },
    getBackgroundColour(statusName: string): string {
      const status = paymentStatusOptions.find(
        status => status.id === statusName
      );
      return status ? status.colour : '#fcbf2b ';
    },
    onBeginRetryPayment(row: IPaymentModel) {
      this.$emit('onBeginRetryPayment', {
        invoiceId: row.id,
        paymentMethodId: row.paymentMethodId,
        subscriptionId: row.subscriptionId,
        requiresAction: row.requiresAction,
        hostedInvoiceUrl: row.hostedInvoiceUrl
      });
    },
    getCurrencySymbolFromCurrencyString(curStr: string): string {
      if (curStr === 'gbp') {
        return '£';
      }
      return curStr;
    }
  }
});

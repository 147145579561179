<template>
  <Skeleton>
    <div class="rm-p--24">
      <PlanLabelAndPriceLoadingSkeleton />

      <div style="display: flex">
        <SkeletonItem>
          <div style="width: 150px;height: 40px; border-radius: 20px"></div>
          <div
            style="width: 250px;height: 24px; border-radius: 10px"
            class="rm-mt--32"
          ></div>
        </SkeletonItem>
      </div>
    </div>
    <div class="rm-mt--24 rm-p--24">
      <PlanLabelAndPriceLoadingSkeleton />
      <SkeletonItem>
        <div
          style="width: 250px;height: 32px; border-radius: 20px"
          class="rm-mb--12"
        ></div>
        <div
          style="width: 200px;height: 24px; border-radius: 20px"
          class="rm-mb--12"
        ></div>
        <div
          style="width: 100%;height: 24px; border-radius: 20px"
          class="rm-mb--24"
        ></div>
      </SkeletonItem>
    </div>
  </Skeleton>
</template>

<script>
import { Skeleton, SkeletonItem } from 'rm-design';
import PlanLabelAndPriceLoadingSkeleton from '@/components/Plan/PlanLabelAndPriceLoadingSkeleton.vue';
export default {
  name: 'SmsPlanSummaryLoadingSkel',
  components: { Skeleton, SkeletonItem, PlanLabelAndPriceLoadingSkeleton }
};
</script>

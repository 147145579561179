














































import { ButtonField } from 'rm-design';
import Vue from 'vue';
import RotifyUrls from '@/constants/RotifyUrls';

export default Vue.extend({
  name: 'OrganisationCreated',
  components: { ButtonField },
  props: {
    userName: {
      type: String,
      required: true,
      default: ''
    },
    converted: {
      type: Boolean,
      default: false
    },
    paymentFailure: {
      type: Boolean,
      default: false
    },
    requiresAction: {
      type: Boolean,
      default: false
    },
    paymentFailureUrl: {
      type: String,
      default: ''
    },
    headerText: {
      type: String,
      default: ''
    }
  },
  methods: {
    onContinue() {
      this.converted ? location.href = this.$store.state.rotifyUrl : location.href = this.$store.state.userGuidesUrl;
    },
    onGoToBilling() {
      this.$router.push('/');
    },
    onAuthenticate() {
      window.open(this.paymentFailureUrl);
    }
  },
  computed: {
    subText(): string {
      if (this.converted) {
        if (!this.paymentFailure) {
          return `Your payment was successful and you are now subscribed to Rotify!`;
        } else if (this.requiresAction) {
          return `Sorry, Looks like your payment requires authorising with your bank. Please click the button below to be taken to authorise the payment`;
        } else {
          return `Sorry, Looks like your payment failed. Please click the button below to go to the billing dashboard to retry the payment`;
        }
      } else {
        return "Thanks for signing up! We're quickly building your brand new Rotify system. It might take a couple of minutes, but you'll receive an email once it's ready. Don't forget to check spam/junk folders";
      }
    }
  }
});

import { httpHandler } from '@/utils/ApiResponseHandler';
import { IApiResponse } from '@/interfaces/common';
import { IGetPaymentsAPIData } from '@/components/LatestPayments/interfaces';

export interface InvoicePaymentHistory {
  paymentDetails: string;
  status: string;
  paymentDate: Date;
}
export async function getPaymentHistory(
  id: string
): Promise<{
  data: InvoicePaymentHistory[];
  error?: boolean;
  message?: string;
}> {
  return httpHandler.get(`/Invoices/PaymentHistory/?id=${id}`);
}
export enum InvoiceStatusTypes {
  Paid,
  Overdue,
  Open
}

export interface IGetInvoicesArg {
  fromDate: string;
  toDate: string;
  subscriptionType?: string;
  status?: string;
  pageSize: number;
  pageNumber: number;
}
export async function getInvoices(
  arg: IGetInvoicesArg
): Promise<IApiResponse<IGetPaymentsAPIData>> {
  let url = `/invoices?dateFrom=${arg.fromDate}&dateTo=${arg.toDate}&pageSize=${arg.pageSize}&pageNumber=${arg.pageNumber}`;
  if (arg.status) {
    url += `&status=${arg.status}`;
  }
  if (arg.subscriptionType) {
    url += `&subscriptionType=${arg.subscriptionType}`;
  }
  return httpHandler.get(url);
}
export interface IPayInvoiceArg {
  invoiceId: string;
  cardId: string;
}
export async function payInvoice(
  arg: IPayInvoiceArg
): Promise<IApiResponse<any>> {
  return httpHandler.post('/invoices/pay', arg);
}

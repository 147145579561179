












































































































































import { ButtonField, Modal, TextField, RadioButton } from 'rm-design';
import Vue from 'vue';
import {
  CancellationReasons,
  CancellationTime
} from '@/components/Plan/constants/CancellationReasons';
import cancelPlanImage from '@/assets/cancelPlan.svg';
import { ICancelSubArg, cancelSubscription } from '@/api/subscriptions';

export default Vue.extend({
  name: 'CancelPlanModal',
  components: {
    Modal,
    RadioButton,
    TextField,
    ButtonField
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    subscriptionId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      CancellationReasons,
      cancelPlanImage,
      CancellationTime,
      cancelReason: '',
      otherReason: '',
      cancelTime: CancellationTime.EndOf,
      immediate: false,
      password: '',
      passwordErrorMsg: '',
      cancelling: false
    };
  },
  methods: {
    onClose() {
      this.otherReason = '';
      this.cancelReason = '';
      this.password = '';
      this.cancelTime = CancellationTime.EndOf;
      this.immediate = false;
      this.$emit('close');
    },
    async submit() {
      if (this.immediate && !this.password.length) {
        this.passwordErrorMsg = 'Required';
        return;
      }

      const data: ICancelSubArg = {
        subscription: this.subscriptionId,
        immediate: this.immediate,
        password: this.password,
        reason:
          this.cancelReason === CancellationReasons.Other
            ? this.otherReason
            : this.cancelReason
      };
      this.cancelling = true;
      const res = await cancelSubscription(data);
      this.cancelling = false;
      if (!res || res.error) {
        this.$snotify.error(res ? res.message : 'Failed to cancel', 'Error');
        return;
      }
      this.$snotify.success('Cancelled subscription', 'Success');
      this.$emit('cancelSubscription');
      this.onClose();
    }
  }
});

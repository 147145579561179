
































import Vue, { PropType } from 'vue';
import PlanSummaryLoadingSkeleton from './PlanSummaryLoadingSkeleton.vue';
import { ISystemBillingData } from '@/components/BillingSummary/BillingSummary';
import NextPaymentCard from '@/components/Plan/NextPaymentCard.vue';
import LastPaymentCard from '@/components/Plan/LastPaymentCard.vue';
import { differenceInCalendarDays } from 'date-fns';

export default Vue.extend({
  name: 'PlanSummary',
  props: {
    planSummaryInformation: {
      type: Object as PropType<ISystemBillingData>,
      required: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    NextPaymentCard,
    LastPaymentCard,
    PlanSummaryLoadingSkeleton
  },
  computed: {
    planPreviousPaymentAmount(): number {
      return this.planSummaryInformation?.previousInvoice?.cost || 0;
    },
    planPreviousPdfUrl(): string {
      return this.planSummaryInformation?.previousInvoice?.invoicePdfUrl || '';
    },
    currentUserCount(): number {
      if (this.planSummaryInformation?.nextInvoice?.quantity) {
        return this.planSummaryInformation.nextInvoice.quantity;
      }
      return 0;
    },
    nextPaymentDate(): Date | null {
      if (this.planSummaryInformation?.nextInvoice?.periodStartDateTime) {
        return new Date(
          this.planSummaryInformation.nextInvoice.periodStartDateTime
        );
      }
      return null;
    },
    planNextPaymentAmount(): number {
      return this.planSummaryInformation?.nextInvoice?.cost || 0;
    },
    lastPaymentDate(): Date | null {
      if (this.planSummaryInformation?.previousInvoice?.paymentDate) {
        return new Date(
          this.planSummaryInformation.previousInvoice.paymentDate
        );
      }
      return null;
    },
    lastPaymentStatus(): string {
      if (this.planSummaryInformation?.previousInvoice?.status) {
        return this.planSummaryInformation?.previousInvoice?.status;
      }
      return 'unknown';
    },
    lastPaymentId(): string {
      return this.planSummaryInformation?.previousInvoice?.id || '';
    },
    daysUntilNextPayment(): number {
      if (this.planSummaryInformation?.nextInvoice?.periodStartDateTime) {
        return differenceInCalendarDays(
          new Date(this.planSummaryInformation.nextInvoice.periodStartDateTime),
          new Date(Date.now())
        );
      }
      return 0;
    },
    workforceLevel(): number {
      return this.planSummaryInformation?.workforceLevel || 0;
    },
    maxUserCount(): number {
      return this.planSummaryInformation?.nextInvoice?.quantity || 0;
    },
    previousMonthUserCount(): number {
      return this.planSummaryInformation?.previousInvoice?.quantity || 0;
    },
    availableUserCount(): number | null {
      if (this.maxUserCount !== null) {
        return this.maxUserCount - this.workforceLevel;
      }
      return null;
    },
    usersTakenPercentage(): number {
      if (this.maxUserCount !== null && this.availableUserCount !== null) {
        const usersTaken = this.maxUserCount - this.availableUserCount;

        const percentage = Math.round((usersTaken / this.maxUserCount) * 100);

        return percentage > 100 ? 100 : percentage;
      }
      return 0;
    },
    periodType(): string {
      return this.planSummaryInformation.planName === 'Annually'
        ? 'year'
        : 'month';
    }
  },
  methods: {
    onRetryPayment(id: string) {
      this.$emit('retryPayment', id);
    }
  }
});

























































import Vue, { PropType } from 'vue';
import { Card, ButtonField } from 'rm-design';
import { format } from 'date-fns';

export default Vue.extend({
  components: { Card, ButtonField },
  props: {
    userCount: {
      type: Number,
      required: true
    },
    cost: {
      type: Number,
      required: true
    },
    paidDate: {
      type: Date as PropType<Date>,
      required: false,
      default: null
    },
    periodType: {
      type: String,
      default: 'month'
    },
    invoicePdfUrl: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      required: false
    },
    paymentId: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    formattedPaidDate(): string {
      return this.paidDate ? format(this.paidDate, 'do LLLL, yyyy') : '';
    },
    displayCost(): string {
      return this.cost ? this.cost.toFixed(2) : '0.00';
    }
  }
});

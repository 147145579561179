








































import Vue, { PropType } from 'vue';
import { ButtonField } from 'rm-design';
import { ISelectField } from '@/interfaces/common';

export default Vue.extend({
  name: 'SubscriptionSummary',
  components: { ButtonField },
  props: {
    costWithoutVat: {
      type: String,
      default: '0'
    },
    costWithVat: {
      type: String,
      default: '0'
    },
    paymentFrequency: {
      type: Object as PropType<ISelectField>,
      required: false
    },
    upperBandLimit: {
      type: Number,
      default: 0
    },
    showPaymentButton: {
      type: Boolean,
      default: false
    },
    paymentButtonDisabled: {
      type: Boolean,
      default: true
    },
    creatingSubscription: {
      type: Boolean
    },
    planName: {
      type: String,
      default: ''
    },
    additionalPlans: {
      type: Array,
      default: () => []
    },
    useSms: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vat(): string {
      return (Number(this.costWithVat) - Number(this.costWithoutVat)).toFixed(
        2
      );
    }
  }
});

const prefix = 'failed-payment-modal__';

export default {
  retryButton: `${prefix}retry-button`,
  beginAddNewCardButton: `${prefix}begin-add-new-card-button`,
  selectSavedCardButton: `${prefix}select-saved-card-button`,
  selectCardGoBackButton: `${prefix}select-card-go-back-button`,
  selectCardButton: `${prefix}select-card-button`,
  confirmGoBackButton: `${prefix}confirm-go-back-button`,
  confirmPaymentButton: `${prefix}confirm-payment-button`,
  paymentSuccessCloseButton: `${prefix}payment-success-close-button`
};






























import Vue from 'vue';
export default Vue.extend({
  name: 'PlanLabelAndPrice',
  props: {
    label: {
      type: String,
      required: true
    },
    secondaryLabel: {
      type: String,
      required: false
    },
    price: {
      type: Number,
      required: false
    },
    period: {
      type: String,
      required: false
    }
  },
  computed: {
    displayPrice(): string {
      return this.price ? this.price.toFixed(2) : '0.00';
    }
  }
});

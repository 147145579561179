<template>
  <Skeleton>
    <div style="display: flex">
      <SkeletonItem>
        <div
          style="width: 334px;height: 174px; border-radius: 20px"
          class="rm-m--12"
        ></div>
      </SkeletonItem>
      <SkeletonItem>
        <div
          style="width: 334px;height: 174px; border-radius: 20px"
          class="rm-m--12"
        ></div>
      </SkeletonItem>
    </div>
    <div style="display: flex">
      <SkeletonItem>
        <div
          style="width: 334px;height: 174px; border-radius: 20px"
          class="rm-m--12"
        ></div>
      </SkeletonItem>
      <SkeletonItem>
        <div
          style="width: 334px;height: 174px; border-radius: 20px"
          class="rm-m--12"
        ></div>
      </SkeletonItem>
    </div>
  </Skeleton>
</template>
<script>
import { Skeleton, SkeletonItem } from 'rm-design';
export default {
  name: 'SavedCardsLoadingSkeleton',
  components: { Skeleton, SkeletonItem }
};
</script>

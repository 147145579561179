





















































import Vue, { PropType } from 'vue';
import { ButtonField, ContextMenu } from 'rm-design';
import { ICard } from '@/components/PaymentDetails/PaymentDetailsInterfaces';

export default Vue.extend({
  name: 'PaymentCard',
  components: {
    ButtonField,
    ContextMenu
  },
  props: {
    cardDetails: {
      type: Object as PropType<ICard>,
      required: true
    },
    includeMakeActiveInContextMenu: {
      type: Boolean,
      default: false
    },
    showContextMenu: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    menuOptions() {
      const options = [
        {
          icon: 'fa-trash',
          label: 'Delete',
          callback: () => this.$emit('delete', this.cardDetails.id)
        }
      ];

      if (this.includeMakeActiveInContextMenu) {
        options.unshift({
          icon: 'fa-credit-card-alt',
          label: 'Make active',
          callback: () => this.$emit('changeActiveCard', this.cardDetails.id)
        });
      }
      return options;
    },
    formattedExpiryDate(): string {
      let month = '';
      if (this.cardDetails?.expiryMonth) {
      month =
        (this.cardDetails.expiryMonth < 10 ? '0' : '') +
        this.cardDetails.expiryMonth.toString();
      }
      let year = '';
      if (this.cardDetails?.expiryYear)  {
        year = this.cardDetails.expiryYear.toString().substring(2);
      }

      return `${month}/${year}`;
    },
    formattedBrand() {
      const brand = this.cardDetails?.brand;
      if (brand) {
        return brand.length > 10 ? `${brand.substring(0, 8)}...` : brand;
      }
      return '';
    }
  }
});

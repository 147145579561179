import { IAuthenticateData, IValidateCredentialsData } from '@/interfaces/user';
import { httpHandler } from '@/utils/ApiResponseHandler';
export interface IUserExistsArg {
  email: string;
  apiServerId: string;
}
export async function userExists(arg: IUserExistsArg): Promise<any> {
  return httpHandler.get(
    `/user/exists/${arg.email}?apiServerId=${arg.apiServerId}`
  );
}
export interface IAuthenticateArg {
  email: string;
  password: string;
  apiServerId: string;
}

export interface IValidateCredentialsArg {
  username: string;
  password: string;
  apiServerId: string;
}

export interface IForgottenPasswordArg {
  email: string;
  apiServerId: string;
}

export interface IVerificationCodeArg {
  verificationCode: string;
  username: string;
  apiServerId: string;
}

export async function authenticate(
  arg: IAuthenticateArg
): Promise<IAuthenticateData> {
  return httpHandler.post(
    '/auth/rotifylogin',
    { ...arg },
    {
      headers: {
        Authorization: null
      }
    }
  );
}

export async function validateCredentials(
  arg: IValidateCredentialsArg
): Promise<IValidateCredentialsData> {
  return httpHandler.post(
    '/auth/validatecredentials',
    { ...arg },
    {
      headers: {
        Authorization: null
      }
    }
  );
}

export async function sendVerificationCodeToUser(): Promise<any> {
  return httpHandler.post('/user/verificationcode');
}

export async function verify(arg: IVerificationCodeArg): Promise<any> {
  return httpHandler.post('/auth/verify', arg);
}

export async function forgottenPassword(
  arg: IForgottenPasswordArg
): Promise<any> {
  return httpHandler.post('/user/forgottenPassword', arg);
}

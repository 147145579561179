import {
  ICardDetails,
  ICardDetailsConfig,
  ITextField
} from '@/components/PaymentDetails/PaymentDetailsInterfaces';

export default class CardDetailsForm implements ICardDetails {
  nameOnCard: ITextField;
  country: ITextField;
  postCode: ITextField;
  makeActiveCard: boolean;

  constructor(config: ICardDetailsConfig = {}) {
    this.nameOnCard = {
      value: config.nameOnCard || '',
      errorMsg: '',
      validate: val => (val ? '' : 'Required')
    };
    this.country = {
      value: config.country || '',
      errorMsg: '',
      validate: val => (val ? '' : 'Required')
    };
    this.postCode = {
      value: config.postCode || '',
      errorMsg: '',
      validate: val => (val ? '' : 'Required')
    };
    this.makeActiveCard = false;
  }
}

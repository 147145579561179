import {
  IOrganisationDetails,
  ISelectField,
  ITextField,
  IOrganisationDetailsConfig,
  IOrganisationDetailsAPIConfig
} from '@/components/SignUp/signUpInterfaces';
import CompanySectorOptions from '@/constants/CompanySectorOptions';

export default class OrganisationDetailsForm implements IOrganisationDetails {
  constructor(config: IOrganisationDetailsConfig = {}) {
    this.name = {
      value: (config && config.name) || '',
      errorMsg: ''
    };
    this.role = {
      value: (config && config.role) || '',
      errorMsg: ''
    };
    this.companySector = {
      value: null,
      errorMsg: '',
      options: CompanySectorOptions
    };
    if (config.sector) {
      const option = CompanySectorOptions.find(x => x.label === config.sector);
      if (option) {
        this.companySector.value = option;
      }
    }
    this.timeZone = {
      value: null,
      errorMsg: '',
      options: (config && config.timeZoneOptions) || null
    };
    if (
      config.timeZone &&
      config.timeZoneOptions &&
      config.timeZoneOptions.length > 0
    ) {
      const option = config.timeZoneOptions.find(
        x => x.label === config.timeZone
      );
      if (option) {
        this.timeZone.value = option;
      }
    }
    this.rotaGroup = {
      value: (config && config.rotaGroup) || '',
      errorMsg: ''
    };
    this.hubspotId = config.hubspotId || null;
  }
  name: ITextField;
  role: ITextField;
  companySector: ISelectField;
  timeZone: ISelectField;
  rotaGroup: ITextField;
  hubspotId: number | null;

  validate(): boolean {
    const requiredFields = [
      this.name,
      this.role,
      this.companySector,
      this.timeZone,
      this.rotaGroup
    ];

    requiredFields.forEach(field => {
      field.errorMsg = field.value ? '' : 'Required';
    });

    return requiredFields.every(field => field.errorMsg === '');
  }

  dataForApi(): IOrganisationDetailsAPIConfig {
    const dataForAPI: IOrganisationDetailsAPIConfig = {
      organisationName: this.name.value,
      roleName: this.role.value,
      sector: this.companySector.value ? this.companySector.value.label : '',
      timeZone: this.timeZone.value ? this.timeZone.value.id : '',
      rotaGroup: this.rotaGroup.value
    };
    if (this.hubspotId) {
      dataForAPI.hubspotId = this.hubspotId;
    }
    return dataForAPI;
  }
}


































































import Vue, { PropType } from 'vue';
import PlanLabelAndPrice from '@/components/Plan/PlanLabelAndPrice.vue';
import SmsPlanSummaryLoadingSkel from './SmsPlanSummaryLoadingSkel.vue';
import { Card, ButtonField } from 'rm-design';
import { IBillingData } from '@/components/BillingSummary/BillingSummary';
import { format } from 'date-fns';
import {
  getDurationPassedInPercentage
} from '@/utils/helpers';
import { differenceInCalendarDays } from 'date-fns';

export default Vue.extend({
  name: 'SmsPlanSummary',
  components: {
    PlanLabelAndPrice,
    Card,
    ButtonField,
    SmsPlanSummaryLoadingSkel
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    smsSummaryInformation: {
      type: Object as PropType<IBillingData>
    },
    systemSubCancelling: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    previousSmsQuantity(): number {
      return this.smsSummaryInformation?.previousInvoice?.quantity || 0;
    },
    nextSmsBillDate(): string {
      let nextDate = '';
      if (this.smsSummaryInformation?.nextInvoice?.periodEndDateTime) {
        nextDate = format(
          new Date(this.smsSummaryInformation?.nextInvoice.periodEndDateTime),
          'do LLLL, yyyy'
        );
      }
      return nextDate;
    },
    nextSmsBillPrice(): number {
      return this.smsSummaryInformation?.nextInvoice?.cost || 0;
    },
    previousSmsBillPrice(): number {
      return this.smsSummaryInformation?.previousInvoice?.cost || 0;
    },
    daysUntilNextPayment(): number | null {
      if (this.smsSummaryInformation?.nextInvoice?.periodEndDateTime) {
        return differenceInCalendarDays(
          new Date(this.smsSummaryInformation.nextInvoice.periodEndDateTime),
          new Date(Date.now())
        );
      }
      return null;
    },
    billProgressPercentage(): number | null {
      const nextInvoice = this.smsSummaryInformation?.nextInvoice;
      if (nextInvoice?.periodEndDateTime && nextInvoice.periodStartDateTime) {
        const billingPeriod = {
          start: new Date(nextInvoice.periodStartDateTime),
          end: new Date(nextInvoice.periodEndDateTime)
        };
        return getDurationPassedInPercentage(
          billingPeriod,
          new Date(Date.now())
        );
      }
      return null;
    }
  }
});











import Vue from 'vue';
import { Skeleton, SkeletonItem } from 'rm-design';
export default Vue.extend({
  name: 'UpdateBillingPeriodSkel',
  components: { Skeleton, SkeletonItem }
});

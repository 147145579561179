

























import Vue from 'vue';
import { SearchAutocomplete, Modal } from 'rm-design';
import { IOrganisation } from '@/interfaces/organisation';
import { mapActions } from 'vuex';

export default Vue.extend({
  name: 'ChangeOrganisationModal',
  components: {
    SearchAutocomplete,
    Modal
  },
  mounted() {
    this.setOrganisations();
  },
  data() {
    return {
      loading: false,
      organisation: null,
      organisations: [] as IOrganisation[]
    };
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title(): string {
      return this.isLogin
        ? 'Please select your organisation'
        : 'Change Organisation';
    },
    body(): string {
      return this.isLogin
        ? 'You need to select an organisation to be redirected to the correct system.'
        : 'Please select an organisation to change to:';
    }
  },
  methods: {
    ...mapActions(['setOrganisation']),
    async changeOrganisation(e) {
      if (e) {
        this.loading = true;
        const organisation: IOrganisation | undefined = this.organisations.find(
          c => c.id === e.id
        );
        if (organisation) {
          await this.setOrganisation(organisation);
        }
        this.loading = false;
        this.$emit('close');
      }
    },
    setOrganisations() {
      const currentOrgId =
        this.$store.state.organisation !== null
          ? this.$store.state.organisation.id
          : '';
      this.organisations = this.$store.state.organisations
        .filter(x => x.id !== currentOrgId)
        .map(y => ({
          ...y,
          label: y.name
        }));
    }
  }
});

import {
  IBillingDetails,
  ITextField,
  ISelectField
} from '@/components/PaymentDetails/PaymentDetailsInterfaces';

export default class BillingDetailsForm implements IBillingDetails {
  addressLine1: ITextField;
  addressLine2: ITextField;
  city: ITextField;
  country: ISelectField;
  postCode: ITextField;
  vatNumber: ITextField;

  constructor(config: any = {}) {
    this.addressLine1 = {
      value: config.addressLine1 || '',
      errorMsg: '',
      validate: val => (val ? '' : 'Required')
    };
    this.addressLine2 = {
      value: config.addressLine2 || '',
      errorMsg: '',
      validate: () => {
        return '';
      }
    };
    this.city = {
      value: config.city || '',
      errorMsg: '',
      validate: val => (val ? '' : 'Required')
    };
    this.country = {
      value: null,
      errorMsg: '',
      options: []
    };
    this.postCode = {
      value: config.postCode || '',
      errorMsg: '',
      validate: val => (val ? '' : 'Required')
    };
    this.vatNumber = {
      value: config.vatNumber || '',
      errorMsg: '',
      validate: () => {
        return '';
      }
    };
  }

  validate(): boolean {
    const requiredFields = [
      this.addressLine1,
      this.city,
      this.country,
      this.postCode
    ];

    requiredFields.forEach(field => {
      field.errorMsg = field.value ? '' : 'Required';
    });
    return requiredFields.every(field => field.errorMsg === '');
  }
}

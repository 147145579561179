


































































































import Vue, { PropType } from 'vue';
import { format } from 'date-fns';
import { Card } from 'rm-design';
import { Carousel, Slide } from 'vue-carousel';
import BillingInfoCircle from './BillingInfoCircle.vue';
import {
  IBillingSummaryApiResponse,
  BillingStatPagesEnum
} from './BillingSummary';

export default Vue.extend({
  name: 'BillingSummary',
  components: { Card, BillingInfoCircle, Carousel, Slide },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    billingData: {
      type: Object as PropType<IBillingSummaryApiResponse>
    }
  },
  data() {
    return {
      currentPage: 0
    };
  },
  computed: {
    systemTierLimit(): number {
      const tierLimit = this.billingData?.system?.tierLimit;
      return tierLimit || 0;
    },
    maxUsers(): number {
      return this.systemTierLimit < this.totalSystemUsers
        ? this.totalSystemUsers
        : this.systemTierLimit;
    },
    planFooterText(): string {
      const remainingUsers = this.systemTierLimit - this.totalSystemUsers;
      if (remainingUsers <= 0) {
        return `You are above your plan limit`;
      }
      return `${remainingUsers} users available for this plan`;
    },
    totalSystemUsers(): number {
      let totalUsers = 0;
      const responseUsers = this.billingData?.system?.workforceLevel;
      if (responseUsers) {
        totalUsers = responseUsers;
      }
      return totalUsers;
    },
    totalSubscriptions(): number {
      let totalSubs = 0;

      if (this.billingData?.system) {
        totalSubs++;
      }

      if (this.billingData?.sms) {
        totalSubs++;
      }
      return totalSubs;
    },
    paidStatus(): { title: string; color: string } {
      let paidStatus;

      if (this.currentPage === BillingStatPagesEnum.System) {
        if (this.billingData?.system?.previousInvoice?.status === 'paid') {
          paidStatus = { title: 'Paid', color: '#4DB6AC' };
        } else if (
          this.billingData?.system?.previousInvoice?.status === 'draft'
        ) {
          paidStatus = { title: 'Pending', color: '#FDBF2A' };
        } else if (
          this.billingData?.system?.previousInvoice?.status === 'requiresAction'
        ) {
          paidStatus = { title: 'Requires Action', color: '#6651a7' };
        } else {
          paidStatus = { title: 'Payment Failed', color: '#FC5E66' };
        }
      } else if (this.currentPage === BillingStatPagesEnum.Sms) {
        if (this.billingData?.sms?.previousInvoice?.status === 'paid') {
          paidStatus = { title: 'Paid', color: '#4DB6AC' };
        } else if (this.billingData?.sms?.previousInvoice?.status === 'draft') {
          paidStatus = { title: 'Pending', color: '#FDBF2A' };
        } else {
          paidStatus = { title: 'Payment Failed', color: '#FC5E66' };
        }
      }
      return paidStatus;
    },
    paidAmount(): string {
      let amount = 0;
      let responseAmount;
      if (this.currentPage === BillingStatPagesEnum.System) {
        responseAmount = this.billingData?.system?.previousInvoice?.cost;
      } else if (this.currentPage === BillingStatPagesEnum.Sms) {
        responseAmount = this.billingData.sms?.previousInvoice?.cost;
      }
      if (responseAmount) {
        amount = responseAmount;
      }
      return amount.toFixed(2);
    },
    nextPaymentDate(): Date | null {
      let date: Date | null = null;
      let responseDate;
      if (this.currentPage === BillingStatPagesEnum.System) {
        responseDate = this.billingData?.system?.nextInvoice
          ?.periodStartDateTime;
      } else if (this.currentPage === BillingStatPagesEnum.Sms) {
        responseDate = this.billingData.sms?.nextInvoice?.periodEndDateTime;
      }
      if (responseDate) {
        date = new Date(responseDate);
      }
      return date;
    },
    nextPaymentShortDate(): string {
      let date = '';
      if (this.nextPaymentDate) {
        date = format(this.nextPaymentDate, 'MMM do');
      }
      if (
        this.currentPage === BillingStatPagesEnum.System &&
        this.billingData.system?.cancellationDate
      ) {
        date = 'Cancelled';
      }
      return date;
    },
    nextPaymentLongDate(): string {
      let date = '';
      if (this.nextPaymentDate) {
        date = format(this.nextPaymentDate, 'MMMM do yyyy');
      }
      return date;
    },
    systemNextPaymentFooterText(): string {
      if (
        this.billingData.system?.cancellationDate &&
        this.billingData.system.previousInvoice?.periodEndDateTime
      ) {
        const currentPeriodEndDate = this.billingData.system.cancellationDate;
        const formattedDate = format(
          new Date(currentPeriodEndDate),
          'MMMM do yyyy'
        );
        if (this.billingData.future) {
          return `Upgrading on ${format(
            new Date(this.billingData.future.startDateTime),
            'MMMM do yyyy'
          )}`;
        } else {
          return `Access until ${formattedDate}`;
        }
      }
      return `Next Payment: ${this.nextPaymentLongDate}`;
    },
    nextPaymentAmount(): string {
      let amount = '';
      let responseAmount;
      if (this.currentPage === BillingStatPagesEnum.System) {
        responseAmount = this.billingData.system?.nextInvoice?.cost;
      } else if (this.currentPage === BillingStatPagesEnum.Sms) {
        responseAmount = this.billingData.sms?.nextInvoice?.cost;
      }
      if (responseAmount) {
        responseAmount = responseAmount.toFixed(2);
        amount = `£${responseAmount}`;
      }
      return amount;
    },
    messagesSentCurrent(): number {
      let count = 0;
      let responseCount;
      if (this.currentPage === BillingStatPagesEnum.Sms) {
        responseCount = this.billingData.sms?.nextInvoice?.quantity;
      }
      if (responseCount) {
        count = responseCount;
      }
      return count;
    },
    messagesSentPrevious(): number {
      let count = 0;
      let responseCount;
      if (this.currentPage === BillingStatPagesEnum.Sms) {
        responseCount = this.billingData.sms?.previousInvoice?.quantity;
      }
      if (responseCount) {
        count = responseCount;
      }
      return count;
    }
  }
});

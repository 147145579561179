import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';

Vue.use(Vuex);

export const initialState = {
  systemCancellationDate: '',
  organisation: null,
  organisations: [],
  token: '',
  refreshToken: '',
  userEmail: '',
  currentUserId: '',
  errorMessage: '',
  apiUrl: '',
  apiServerId: '',
  subscriptionId: '',
  smsSubscriptionId: '',
  systemCost: 0,
  systemWorkforceLevel: 0,
  rotifyUrl: '',
  futureSubscriptionExists: false,
  shouldCheckForLatestFailedPayment: false,
  annualPlanExceeded: false,
  proRataDate: '',
  paymentCards: [],
  termsAndConditionsUrl: 'https://termsandconditions.rotify.co.uk/',
  privacyPolicyUrl: 'https://privacy.rotify.co.uk/',
  userGuidesUrl: 'https://rotamaster.atlassian.net/servicedesk/customer/portals'
};
const state = Vue.util.extend({}, initialState);

export const store = new Vuex.Store({
  state,
  mutations,
  actions
});

store.subscribe((mutation, state) => {
  const keys = ['apiUrl', 'apiServerId', 'rotifyUrl'];

  const stateClone = {};
  keys.forEach(key => {
    stateClone[key] = state[key];
  });
  localStorage.setItem('store', JSON.stringify(stateClone));

  const sessionKeys = [
    'token',
    'refreshToken',
    'currentUserId',
    'organisation',
    'organisations',
    'userEmail',
    'shouldCheckForLatestFailedPayment'
  ];
  const sessionStateClone = {};
  sessionKeys.forEach(key => {
    sessionStateClone[key] = state[key];
  });
  sessionStorage.setItem('store', JSON.stringify(sessionStateClone));
});







































import Vue from 'vue';
export const StatusTypes = {
  Success: 'success',
  Error: 'error',
  Default: 'default'
};

export default Vue.extend({
  name: 'CheckListItem',
  data() {
    return {
      StatusTypes
    };
  },
  props: {
    status: {
      type: String,
      default: StatusTypes.Default
    },
    label: {
      type: String,
      required: true
    }
  }
});

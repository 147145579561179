import { httpHandler } from '@/utils/ApiResponseHandler';

export interface IVerificationCodeArg {
  username: string;
  apiServerId: string;
}

export interface ICreateRotifyUser {
  email: string;
  password: string;
  apiServerId: string;
}

export const selectOrg = async (orgId: string): Promise<any> => {
  return httpHandler.post('/auth/selectOrganisation', {
    id: orgId
  });
};

export const sendVerificationCode = async (
  details: IVerificationCodeArg
): Promise<any> => {
  return httpHandler.post('/auth/verificationcode', details);
};

export const createRotifyUser = (details: ICreateRotifyUser): Promise<any> => {
  return httpHandler.post('/auth/rotifyuser', details);
};

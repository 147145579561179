


































































































import Vue from 'vue';
import SlideshowSplitLayout from '@/components/SlideshowSplitLayout/SlideshowSplitLayout.vue';
import SnakeStepTracker from '@/components/SignUp/SnakeStepTracker.vue';
import SignUpStates from '@/components/SignUp/constants/SignUpStates';
import PersonalDetailsForm from '@/components/SignUp/PersonalDetailsForm.vue';
import OrganisationDetailsForm from '@/components/SignUp/OrganisationDetailsForm.vue';
import CreatePasswordForm from '@/components/SignUp/CreatePasswordForm.vue';
import VerificationForm from '@/components/SignUp/VerificationForm.vue';
import SignUpSkeleton from '@/components/SignUp/SignUpSkeleton.vue';
import OrganisationCreated from '@/components/SignUp/OrganisationCreated.vue';
import ExistingUserPasswordForm from '@/components/SignUp/ExistingUserPasswordForm.vue';
import PersonalDetailsFormModel from '@/components/SignUp/models/PersonalDetailsForm';
import OrganisationDetailsFormModel from '@/components/SignUp/models/OrganisationDetailsForm';
import PasswordFormModel from '@/components/PasswordCheckList/models/PasswordForm';
import { createRotifyUser, ICreateRotifyUser } from '@/api/auth';
import {
  getOrgDetails,
  IGetOrgDetailsArg,
  createOrg,
  creationIsEnabled
} from '@/api/organisations';
import { ButtonField } from 'rm-design';
import {
  IOrganisationDetailsAPIConfig,
  IRegistrationAPIConfig,
  IPersonalDetailsAPIConfig,
  IPasswordAPIConfig,
  ISelectFieldValue
} from '@/components/SignUp/signUpInterfaces';
import { validateCredentials, IValidateCredentialsArg } from '@/api/user';
import { getSystemTimezones } from '@/api/organisations';
import BeeGraphicSvg from '@/assets/bee.svg';
import EmptyStateBg from '@/assets/empty-state-bg.png';
import RotifyUrls from '@/constants/RotifyUrls';

export default Vue.extend({
  name: 'Register',
  components: {
    SlideshowSplitLayout,
    SnakeStepTracker,
    PersonalDetailsForm,
    OrganisationDetailsForm,
    CreatePasswordForm,
    VerificationForm,
    ExistingUserPasswordForm,
    ButtonField,
    OrganisationCreated,
    SignUpSkeleton
  },
  data() {
    return {
      BeeGraphicSvg,
      EmptyStateBg,
      SignUpStates,
      currentStep: SignUpStates.PersonalDetails,
      personalDetailsFormModel: new PersonalDetailsFormModel(),
      organisationDetailsFormModel: new OrganisationDetailsFormModel(),
      passwordFormModel: new PasswordFormModel(),
      loading: false,
      timeZoneOptions: Array<ISelectFieldValue>(),
      userExists: false,
      existingUserPassword: '',
      successfullyCreated: false,
      gettingOrgDetails: false,
      userRequiresVerification: false,
      creationIsDisabled: false
    };
  },
  computed: {
    headerText(): string {
      return this.currentStep !== SignUpStates.Success
        ? 'Start your 14 day free trial of Rotify'
        : `${this.organisationDetailsFormModel.name.value} is being created!`;
    },
    showVerificationStep(): boolean {
      return (
        this.currentStep === SignUpStates.Password &&
        this.userRequiresVerification
      );
    }
  },
  async mounted() {
    const creationIsEnabled = await this.checkCreationIsEnabled();
    this.creationIsDisabled = !creationIsEnabled;
    if (creationIsEnabled) {
      this.getTimeZones();
      this.getOrgDetails();
    }
  },
  methods: {
    async checkCreationIsEnabled(): Promise<boolean> {
      const serverId = this.$store.state.apiServerId;
      const res = await creationIsEnabled(serverId);
      return res.data;
    },
    goToRotify() {
      window.location.replace(RotifyUrls.RotifyWebsiteUrl);
    },
    async getOrgDetails() {
      let orgId = '';
      const rawOrgId: string | (string | null)[] = this.$route.query.orgId;
      if (rawOrgId === undefined) {
        return;
      }
      if (Array.isArray(rawOrgId)) {
        if (rawOrgId[0] !== null) {
          orgId = rawOrgId[0];
        }
      } else {
        if (rawOrgId !== null) {
          orgId = rawOrgId;
        }
      }
      let userId = '';
      const rawUserId: string | (string | null)[] = this.$route.query.userId;
      if (rawUserId === undefined) {
        return;
      }
      if (Array.isArray(rawUserId)) {
        if (rawUserId[0] !== null) {
          userId = rawUserId[0];
        }
      } else {
        if (rawUserId !== null) {
          userId = rawUserId;
        }
      }

      if (orgId.length === 0 || userId.length === 0) {
        return;
      }
      // leaving these here for now for debugging
      // const orgId = '70bdcfc1-1ed8-4c6c-b7c6-f8aa6533dae4';
      // const userId = 'fff5bcb1-097c-4d4e-a001-8c5e902941a3';
      this.gettingOrgDetails = true;
      const arg: IGetOrgDetailsArg = {
        orgId,
        userId,
        apiServerId: this.$store.state.apiServerId
      };
      const res = await getOrgDetails(arg);
      this.gettingOrgDetails = false;
      if (res && !res.error && res.data !== '') {
        this.organisationDetailsFormModel = new OrganisationDetailsFormModel({
          ...res.data,
          timeZoneOptions: this.timeZoneOptions
        });
        this.personalDetailsFormModel = new PersonalDetailsFormModel({
          ...res.data
        });
      }
    },
    async authenticateExistingUser(password: string) {
      const email = this.personalDetailsFormModel.email.value;
      const details: IValidateCredentialsArg = {
        username: email,
        password: password,
        apiServerId: this.$store.state.apiServerId
      };

      this.loading = true;
      const response = await validateCredentials(details);
      this.loading = false;

      if (response.error) {
        this.$snotify.error(
          response.message ?? 'Error authenticating',
          'Error'
        );
        return;
      }

      if (response.data?.validCredentials === false) {
        this.$snotify.error(
          response.data.errors[0]?.message ?? 'Error authenticating',
          'Error'
        );
        return;
      }

      if (response.data?.requiresVerification) {
        this.userRequiresVerification = true;
      } else {
        this.currentStep = SignUpStates.Organisation;
      }
    },
    async createOrganisation() {
      if (!this.organisationDetailsFormModel.validate()) {
        this.$snotify.error(
          'Validation error, please check form details and try again',
          'Error'
        );
        return;
      }

      const organisationDetails: IOrganisationDetailsAPIConfig = this.organisationDetailsFormModel.dataForApi();
      const personalDetails: IPersonalDetailsAPIConfig = this.personalDetailsFormModel.dataForApi();
      const passwordDetails: IPasswordAPIConfig = this.passwordFormModel.dataForApi();

      const form: IRegistrationAPIConfig = {
        ...organisationDetails,
        ...personalDetails,
        ...passwordDetails,
        apiServerId: this.$store.state.apiServerId as string
      };

      if (this.userExists) {
        form.password = this.existingUserPassword;
      }

      this.loading = true;
      const orgCreateResponse = await createOrg(form);
      this.loading = false;

      if (!orgCreateResponse || orgCreateResponse.error) {
        this.$snotify.error(orgCreateResponse.message, 'Error');
        return;
      }

      this.currentStep = SignUpStates.Success;
    },
    openPrivacyPolicy() {
      window.open(this.$store.state.privacyPolicyUrl);
    },
    openTermsAndConditions() {
      window.open(this.$store.state.termsAndConditionsUrl);
    },
    async getTimeZones() {
      const response = await getSystemTimezones();
      if (response && !response.error) {
        this.timeZoneOptions = response.data.map(x => ({
          id: x.code,
          label: x.displayName
        }));
      }
    },
    async onPersonalDetailsContinue(userExists: boolean) {
      this.userExists = userExists;
      this.currentStep = SignUpStates.Password;
    },
    async createRotifyUser() {
      const details: ICreateRotifyUser = {
        email: this.personalDetailsFormModel.email.value,
        password: this.passwordFormModel.password.value,
        apiServerId: this.$store.state.apiServerId
      };

      this.loading = true;
      const response = await createRotifyUser(details);
      this.loading = false;

      if (!response.error) {
        this.userRequiresVerification = true;
      } else {
        this.$snotify.error(response.error.message, 'Error');
        return;
      }
    },
    onVerificationSuccess() {
      this.currentStep = SignUpStates.Organisation;
    },
    reset() {
      this.personalDetailsFormModel = new PersonalDetailsFormModel();
      this.organisationDetailsFormModel = new OrganisationDetailsFormModel();
      this.userExists = false;
      this.currentStep = SignUpStates.PersonalDetails;
      this.passwordFormModel = new PasswordFormModel();
      this.existingUserPassword = '';
      this.successfullyCreated = false;
      this.userRequiresVerification = false;
    }
  }
});

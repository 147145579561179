<template>
  <div
    class="slideshow-img-container"
    :style="{ height: !isMobileView ? '100%' : '' }"
  >
    <div v-if="isMobileView" class="rm-p-ends--16" style="margin:auto;">
      <img style="height: 30px;" :src="rotifyLogo" alt="Rotify" />
    </div>
    <transition name="fade" mode="out-in">
      <img
        class="slideshow-img"
        alt="Slideshow Image"
        :src="currentImage.image"
        :key="currentImage.id"
      />
    </transition>
    <div v-if="!isMobileView" class="slideshow-img-text">
      <h3 class="rm-mb--12">{{ images[currentImageIndex].title }}</h3>
      <p>{{ images[currentImageIndex].description }}</p>
      <div class="rm-mt--12">
        <Button
          class="rm-mr--16 rm-slideshow-btn"
          @click="
            clearImageInterval();
            cycleImageForward();
          "
          ref="go-back-week-button"
          aria-label="Next Image"
        >
          <i class="fa fa-chevron-left"></i>
        </Button>
        <Button
          class="rm-slideshow-btn"
          @click="
            clearImageInterval();
            cycleImageForward();
          "
          aria-label="Previous Image"
        >
          <i class="fa fa-chevron-right"></i>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import rotaImg from '@/assets/Login/Rota.png';
import shiftNotesImg from '@/assets/Login/ShiftNotes.png';
import dashboardImg from '@/assets/Login/Dashboard.png';
import mobileRotaImg from '@/assets/Login/MobileRota.png';
import mobileShiftNotesImg from '@/assets/Login/MobileShiftNotes.png';
import mobileDashboardImg from '@/assets/Login/MobileDashboard.png';
import rotifyLogo from '@/assets/Logo-Purple.svg';
import DeviceBreakPoints from '@/constants/DeviceBreakPoints';

export default Vue.extend({
  components: {},
  created() {
    this.setupImageInterval();
  },
  computed: {
    currentImage() {
      return this.isMobileView
        ? this.mobileImages[this.currentImageIndex]
        : this.images[this.currentImageIndex];
    }
  },
  data() {
    return {
      rotifyLogo,
      isMobileView: false,
      imageInterval: 0,
      currentImageIndex: 0,
      images: [
        {
          id: 1,
          title: 'Simplify staff scheduling.',
          description:
            'Build staff rotas in minutes. Distribute them in seconds.',
          image: rotaImg
        },
        {
          id: 2,
          title: 'Plan. Notify. Rotify.',
          description:
            'In-app messages, SMS and email keep everyone in the loop.',
          image: shiftNotesImg
        },
        {
          id: 3,
          title: 'Quantify. Identify. Rotify.',
          description:
            'Clear dashboards and powerful reports keep everything in check.',
          image: dashboardImg
        }
      ],
      mobileImages: [
        {
          id: 1,
          image: mobileRotaImg
        },
        {
          id: 2,
          image: mobileShiftNotesImg
        },
        {
          image: mobileDashboardImg
        }
      ]
    };
  },
  mounted() {
    this.onWindowResize();
    window.addEventListener('resize', this.onWindowResize);
    this.getBase();
  },
  methods: {
    getBase() {
      this.images.forEach(x => {
        this.toDataURL({ src: x.image, callback: data => (x.image = data) });
      });
      this.mobileImages.forEach(x => {
        this.toDataURL({ src: x.image, callback: data => (x.image = data) });
      });
    },
    toDataURL({ src, callback, outputFormat }) {
      var img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function() {
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        if (ctx) {
          ctx.drawImage(img, 0, 0);
        }
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
      };
      img.src = src;
      if (img.complete || img.complete === undefined) {
        img.src =
          'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
        img.src = src;
      }
    },
    onWindowResize() {
      this.isMobileView = window.innerWidth <= DeviceBreakPoints.Small;

      if (this.isMobileView && this.imageInterval === null) {
        this.setupImageInterval();
      }
    },
    setupImageInterval() {
      this.imageInterval = setInterval(() => this.cycleImageForward(), 5000);
    },
    cycleImageForward() {
      const imageArrayLength = this.isMobileView
        ? this.mobileImages.length
        : this.images.length;

      if (this.currentImageIndex !== imageArrayLength - 1) {
        this.currentImageIndex++;
      } else {
        this.currentImageIndex = 0;
      }
    },
    cycleImageBackwards() {
      const imageArrayLength = this.isMobileView
        ? this.mobileImages.length
        : this.images.length;
      if (this.currentImageIndex !== 0) {
        this.currentImageIndex--;
      } else {
        this.currentImageIndex = imageArrayLength - 1;
      }
    },
    clearImageInterval() {
      clearInterval(this.imageInterval);
      this.imageInterval = 0;
    }
  }
});
</script>

<style>
.rm-slideshow-btn {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.07);
  background: #ffffff;
  border: 1px solid transparent;
  cursor: pointer;
}

.rm-slideshow-btn:focus {
  border: none;
  outline: none;
}

.slideshow-img {
  max-width: 100%;
  max-height: 100%;
  min-height: 70%;
  min-width: 100%;
  object-fit: scale-down;
}

.slideshow-img__hide {
  visibility: hidden;
}

.slideshow-img-text {
  margin: auto;
  text-align: center;
}

.slideshow-img-container {
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
</style>

import { IApiResponse } from '@/interfaces/common';
import { ICard } from '@/components/PaymentDetails/PaymentDetailsInterfaces';

export const BillingStatPagesEnum = {
  System: 0,
  Sms: 1
};
export interface IMetaData {
  totalSubscriptions: number;
}
export interface IPlanStat {
  totalUsers: number;
  maxUsers: number;
}
export interface IBillingPeriodStat {
  paid: boolean;
  amount: number;
  messageCount?: number;
}
export interface INextPaymentStat {
  due: string;
  amount: number;
}

export interface IMessagesStat {
  messagesSent: number;
}

export interface IInvoiceLines {
  id: string;
  cost: number;
  description: string;
}

export interface IInvoice {
  customerEmail: string;
  planName: string;
  cost: number;
  currency: string;
  quantity: number;
  createdDateTime: Date | string;
  periodStartDateTime: Date | string;
  periodEndDateTime: Date | string;
  status: string;
  invoiceLines: IInvoiceLines[];
}

export interface IInvoicePrevious extends IInvoice {
  id: string;
  invoiceNumber: string;
  invoicePdfUrl: string;
  paymentDate: Date | string;
}

export interface IInvoiceFuture extends IInvoice {
  id: null;
  invoiceNumber: null;
  invoicePdfUrl: null;
  paymentDate: null;
}

export interface IBillingData {
  subscriptionId: string;
  billingType: number;
  activeCard: ICard;
  previousInvoice?: IInvoicePrevious;
  nextInvoice?: IInvoiceFuture;
  planName?: string;
  value: number;
  startDateTime?: string;
}

export interface ISystemBillingData extends IBillingData {
  workforceLevel: number;
  cancellationDate: null | string;
  tierLimit: number;
}

export interface IFutureBillingData {
  id: string;
  startDateTime: string;
  scheduledOnDateTime: string;
  quantity: number;
  cardDetails: ICard;
}

export interface IBillingSummaryApiResponse {
  sms?: IBillingData | null;
  system?: ISystemBillingData;
  future?: IFutureBillingData | null;
}

export const getBillingSummary = (): IApiResponse<IBillingSummaryApiResponse> => {
  const response = {
    data: {
      system: {
        subscriptionId: 'sub_JIehanMBuYHxTg',
        cancellationDate: null /*to mimic a cancelled plan set this value & set 'nextInvoice' to null*/,
        billingType: 0,
        workforceLevel: 450,
        value: 999,
        tierLimit: 500,
        activeCard: {
          id: 'SystemActiveCard',
          numberLastFour: '4242',
          expiryMonth: 4,
          expiryYear: 2024,
          cardHolderName: 'Mrs Card Holder',
          brand: 'Visa'
        },
        previousInvoice: {
          id: 'in_1Ig3O8Hv0YtMCxesicgNcYZi',
          invoiceNumber: 'DED4CF80-0215',
          customerEmail: 'brad.vaile@rotamaster.co.uk',
          paymentDate: '2021-04-14T07:43:52Z',
          planName: '552 × System (Tier 1 at £2.00 / month)',
          cost: 1200,
          currency: 'gbp',
          invoicePdfUrl:
            'https://pay.stripe.com/invoice/acct_1GOh4dHv0YtMCxes/invst_JIehznAaMvisaFY24oaDcPFqNqcKUwO/pdf',
          quantity: 520,
          createdDateTime: '2021-04-14T07:43:52Z',
          periodStartDateTime: '2021-04-14T07:43:52Z',
          periodEndDateTime: '2021-04-14T07:43:52Z',
          status: 'paid',
          invoiceLines: [
            {
              id: 'il_1Ig3O8Hv0YtMCxesV1tyU34J',
              cost: 1104,
              description: '552 × System (Tier 1 at £2.00 / month)'
            },
            {
              id: 'il_1Ig3O9Hv0YtMCxesphwHuYhL',
              cost: 0,
              description: 'System (Tier 1 at £0.00 / month)'
            }
          ]
        },
        nextInvoice: {
          id: null,
          invoiceNumber: null,
          customerEmail: 'brad.vaile@rotamaster.co.uk',
          paymentDate: null,
          planName: '552 × System (Tier 1 at £2.00 / month)',
          cost: 1324,
          currency: 'gbp',
          invoicePdfUrl: null,
          quantity: 552,
          createdDateTime: '2021-05-14T07:43:52Z',
          periodStartDateTime: '2021-04-14T07:43:52Z',
          periodEndDateTime: '2021-05-14T07:43:52Z',
          status: 'draft',
          invoiceLines: [
            {
              id: 'il_tmp_1cec52Hv0YtMCxes910b0b01',
              cost: 1104,
              description: '552 × System (Tier 1 at £2.00 / month)'
            },
            {
              id: 'il_tmp_10a1acHv0YtMCxesbfd5b963',
              cost: 0,
              description: 'System (Tier 1 at £0.00 / month)'
            }
          ]
        }
      },
      sms: {
        subscriptionId: 'sub_JIehanMBuYHxTg',
        billingType: 0,
        value: 999,
        activeCard: {
          id: 'SMSActiveCard',
          numberLastFour: '4242',
          expiryMonth: 4,
          expiryYear: 2024,
          cardHolderName: 'Mr Card Holder',
          brand: 'Visa'
        },
        previousInvoice: {
          id: 'in_1Ig3O8Hv0YtMCxesicgNcYZi',
          invoiceNumber: 'DED4CF80-0215',
          customerEmail: 'brad.vaile@rotamaster.co.uk',
          paymentDate: '2021-04-14T07:43:52Z',
          planName: '552 × System (Tier 1 at £2.00 / month)',
          cost: 100,
          currency: 'gbp',
          invoicePdfUrl:
            'https://pay.stripe.com/invoice/acct_1GOh4dHv0YtMCxes/invst_JIehznAaMvisaFY24oaDcPFqNqcKUwO/pdf',
          quantity: 552,
          createdDateTime: '2021-04-14T07:43:52Z',
          periodStartDateTime: '2021-04-14T07:43:52Z',
          periodEndDateTime: '2021-04-14T07:43:52Z',
          status: 'paid',
          invoiceLines: [
            {
              id: 'il_1Ig3O8Hv0YtMCxesV1tyU34J',
              cost: 1104,
              description: '552 × System (Tier 1 at £2.00 / month)'
            },
            {
              id: 'il_1Ig3O9Hv0YtMCxesphwHuYhL',
              cost: 0,
              description: 'System (Tier 1 at £0.00 / month)'
            }
          ]
        },
        nextInvoice: {
          id: null,
          invoiceNumber: null,
          customerEmail: 'brad.vaile@rotamaster.co.uk',
          paymentDate: null,
          planName: '552 × System (Tier 1 at £2.00 / month)',
          cost: 52,
          currency: 'gbp',
          invoicePdfUrl: null,
          quantity: 552,
          createdDateTime: '2021-05-14T07:43:52Z',
          periodStartDateTime: '2021-04-13T07:43:52Z',
          periodEndDateTime: '2021-05-13T07:43:52Z',
          status: 'draft',
          invoiceLines: [
            {
              id: 'il_tmp_1cec52Hv0YtMCxes910b0b01',
              cost: 1104,
              description: '552 × System (Tier 1 at £2.00 / month)'
            },
            {
              id: 'il_tmp_10a1acHv0YtMCxesbfd5b963',
              cost: 0,
              description: 'System (Tier 1 at £0.00 / month)'
            }
          ]
        }
      },
      future: {
        id: 'sub_sched_1Igs10Hv0YtMCxesntBqqZfY',
        startDateTime: '2022-04-16T13:30:20Z',
        scheduledOnDateTime: '2021-04-16T13:47:22Z',
        quantity: 550,
        cardDetails: {
          id: 'futureCardId',
          numberLastFour: '4242',
          expiryMonth: 4,
          expiryYear: 2024,
          cardHolderName: 'Mr Future Card',
          brand: 'Visa'
        }
      }
    }
  };

  return response;
};








































































import { TextField, ButtonField } from 'rm-design';
import ExisitingUserPasswordIds from './constants/ExisitngUserPasswordIds';
import Vue from 'vue';
import { forgottenPassword, IForgottenPasswordArg } from '@/api/user';

export default Vue.extend({
  name: 'ExistingUserPasswordForm',
  components: { TextField, ButtonField },
  props: {
    email: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      ExisitingUserPasswordIds,
      forgottenPassword: false,
      processingForgottenPassword: false,
      forgottenPasswordProcessed: false,
      forgottenPasswordErrorMessage: ''
    };
  },
  computed: {
    passwordValue: {
      get(): string {
        return this.value;
      },
      set(val: string) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    onForgotten() {
      this.forgottenPassword = true;
    },
    async onProcessForgotten() {
      this.processingForgottenPassword = true;
      this.forgottenPasswordProcessed = false;
      try {
        const arg: IForgottenPasswordArg = {
          email: this.email,
          apiServerId: this.$store.state.apiServerId
        };
        const response = await forgottenPassword(arg);
        if (!response.error) {
          this.forgottenPasswordProcessed = true;
        } else {
          this.forgottenPasswordErrorMessage = response.message;
        }
      } catch (exception) {
        this.$snotify.error(exception, 'Error');
      }
      this.processingForgottenPassword = false;
    }
  }
});











































import Vue, { PropType } from 'vue';
import { Carousel, Slide } from 'vue-carousel';
import { ButtonField } from 'rm-design';
import PaymentCard from '@/components/PaymentDetails/PaymentCard.vue';
import SavedCardsLoadingSkeleton from '@/components/SavedCards/SavedCardsLoadingSkeleton.vue';
import { ICard } from '@/components/PaymentDetails/PaymentDetailsInterfaces';
export default Vue.extend({
  name: 'SavedCards',
  components: {
    Carousel,
    Slide,
    PaymentCard,
    ButtonField,
    SavedCardsLoadingSkeleton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    activeCardId: {
      type: String,
      required: false
    }
  },
  computed: {
    activeCard(): ICard | null {
      const card = this.cardList.find(card => card.id === this.activeCardId);
      if (card) {
        return { ...card, active: true };
      }
      return null;
    },
    inactiveCardList(): ICard[] {
      let inactiveCardList: ICard[] = [];
      if (this.cardList) {
        inactiveCardList = this.cardList
          .filter(card => card.id !== this.activeCardId)
          .map(x => ({ ...x, active: false }));
      }
      return inactiveCardList;
    },
    cardList(): ICard[] {
      return this.$store.state.paymentCards;
    }
  },
  methods: {
    onDeleteCard(cardId: string) {
      this.$emit('deleteCard', cardId);
    },
    onChangeActiveCard(cardId) {
      this.$emit('changeActiveCard', cardId);
    }
  }
});

<template>
  <div>
    <div
      class="slideshow-split-layout__split slideshow-split-layout__content-container"
    >
      <div class="slideshow-split-layout__centered">
        <slot></slot>
      </div>
    </div>
    <div
      class="slideshow-split-layout__split slideshow-split-layout__slideshow-container"
    >
      <ImageSlideshow />
    </div>
  </div>
</template>

<script>
import ImageSlideshow from './ImageSlideshow';
import Vue from 'vue';

export default Vue.extend({
  name: 'SlideshowSplitLayout',
  components: {
    ImageSlideshow
  }
});
</script>

<style>
.slideshow-split-layout__content-container {
  padding: 24px 16px 36px 16px;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 -18px 30px 0 rgba(0, 0, 0, 0.07);
  border-radius: 16px 16px 0 0;
  z-index: var(--z-index-30);
}
.slideshow-split-layout__centered {
  height: 400px;
  overflow-y: auto;
}

.slideshow-split-layout__slideshow-container {
  height: 100vh;
  background-color: #f5f5f5;
}

@media screen and (min-width: 1024px) {
  .slideshow-split-layout__split {
    height: 100%;
    position: fixed;
    top: 0;
    overflow-x: hidden;
    box-shadow: none;
    position: absolute;
  }

  .slideshow-split-layout__content-container {
    left: 0;
    width: 45%;
    position: absolute;
  }
  .slideshow-split-layout__slideshow-container {
    right: 0;
    width: 55%;
  }

  @media screen and (max-height: 800px) {
    .slideshow-split-layout__centered {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }

  .slideshow-split-layout__centered {
    height: initial;
    overflow-y: initial;
    position: absolute;
    width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>




























































import Vue from 'vue';
import { ContextHelper, RadioButton, ButtonField } from 'rm-design';
import { getCurrentWorkforceLevel } from '@/api/organisations';

export default Vue.extend({
  name: 'PlanCustomisation',
  components: { ContextHelper, RadioButton, ButtonField },
  async mounted() {
    await this.setMinimumWorkforceLevel();
  },
  data() {
    return {
      minEmployeeCount: 1
    };
  },
  props: {
    useSms: {
      type: Boolean,
      default: false
    },
    employeeCount: {
      type: Number,
      default: 1
    },
    paymentFrequency: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    },
    paymentFrequencyOptions: {
      type: Array,
      required: true
    }
  },
  computed: {
    employeeCountValue: {
      get(): number {
        return this.employeeCount;
      },
      set(value: number): void {
        this.$emit('employeeCountChange', Number(value));
      }
    },
    useSmsValue: {
      get(): boolean {
        return this.useSms;
      },
      set(value: boolean): void {
        this.$emit('useSmsChange', value);
      }
    },
    paymentFrequencyValue: {
      get(): string {
        return this.paymentFrequency.label;
      },
      set(value: string): void {
        this.$emit('paymentFrequencyChange', value);
      }
    },
    employeeErrorMessage(): string {
      let errorMessage = '';
      if (this.employeeCountValue !== null) {
        if (this.employeeCountValue <= 0) {
          errorMessage = 'Employee count must be more than 0';
        }
        if (this.employeeCountValue < this.minEmployeeCount) {
          errorMessage =
            'Employee count cannot be less than current workforce level';
        }
      }
      return errorMessage;
    }
  },
  methods: {
    onEmployeeCountBlur() {
      if (isNaN(this.employeeCountValue)) {
        this.$emit('employeeCountChange', 1);
      } else {
        if (this.employeeCountValue < 1) {
          this.$emit('employeeCountChange', 1);
        }
      }
    },
    async setMinimumWorkforceLevel() {
      const wflResponse = await getCurrentWorkforceLevel(this.getOrgId());
      if (!wflResponse.error) {
        this.minEmployeeCount = wflResponse.data.workForceLevel;
        this.$emit(
          'employeeCountChange',
          Number(wflResponse.data.workForceLevel)
        );
      }
    },
    getOrgId(): string {
      let orgId = '';
      const rawOrgId: string | (string | null)[] = this.$route.query.orgId;
      if (rawOrgId === undefined) {
        return '';
      }
      if (Array.isArray(rawOrgId)) {
        if (rawOrgId[0] !== null) {
          orgId = rawOrgId[0];
        }
      } else {
        if (rawOrgId !== null) {
          orgId = rawOrgId;
        }
      }
      return orgId;
    }
  }
});

import '@babel/polyfill';
import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import Snotify from 'vue-snotify';
import 'vue-snotify/styles/material.css';
import '@/assets/style.css';
import '@/assets/flexboxgrid.min.css';
import '@/assets/flexboxgrid-helpers.min.css';
import 'rm-design/dist/rm-design.css';
import { store } from './store/store.js';

Vue.use(Snotify);
Vue.use(VueRouter);
Vue.config.productionTip = false;

new Vue({
  store,
  beforeCreate() {
    this.$store.commit('initialiseStore');
  },
  render: h => h(App)
}).$mount('#app');















import Vue from 'vue';

export default Vue.extend({
  name: 'ProgressBar',
  props: {
    percentFilled: {
      type: Number,
      required: true
    }
  }
});

import { httpHandler } from '@/utils/ApiResponseHandler';

export default class Privacy {
  static get(): Promise<any> {
    return httpHandler.get('/Privacy', {
      headers: {
        Authorization: null
      }
    });
  }
}

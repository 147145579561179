





















































import Vue, { PropType } from 'vue';

import { ButtonField, DateRangePicker, Pager } from 'rm-design';
import InvoicePaymentHistoryModal from './InvoicePaymentHistoryModal.vue';
import latestPaymentsIds from './constants/ids/latestPaymentIds';
import PaymentsTable from './PaymentsTable.vue';
import PaymentFilterOverlay from './PaymentFilterOverlay.vue';
import {
  IPaymentFilters,
  IFailedPaymentModel,
  IPaymentModel
} from './interfaces';
import { IPager } from '@/interfaces/common';

export interface ILatestPaymentsMetaData {
  filters: IPaymentFilters;
  dateRange: null[] | Date[];
  pager: IPager;
}
export default Vue.extend({
  name: 'LatestPayments',
  components: {
    ButtonField,
    DateRangePicker,
    Pager,
    PaymentsTable,
    PaymentFilterOverlay,
    InvoicePaymentHistoryModal
  },
  props: {
    value: {
      type: Object as PropType<ILatestPaymentsMetaData>,
      required: true
    },
    loading: {
      type: Boolean
    },
    data: {
      type: Array as PropType<IPaymentModel[]>
    }
  },
  data() {
    return {
      latestPaymentsIds,
      filterOverlayOpen: false,
      invoicePaymentHistoryModalIsOpen: false,
      paymentId: '',
      internalDateRange: [null, null]
    };
  },
  computed: {
    filterCount(): number {
      let count = 0;
      if (this.value.filters.subscriptionType) {
        count++;
      }
      if (this.value.filters.status) {
        count++;
      }
      return count;
    },
    dateRange: {
      get(): any {
        return this.value.dateRange;
      },
      set(val: any): void {
        this.emitChange({ ...this.value, dateRange: val });
      }
    }
  },
  methods: {
    onHistoryButtonClick(id: string) {
      this.paymentId = id;
      this.invoicePaymentHistoryModalIsOpen = true;
    },
    async onSetFilters(filters: IPaymentFilters) {
      this.emitChange({ ...this.value, filters });
    },
    onBeginRetryPayment(failedModel: IFailedPaymentModel) {
      this.$emit('onBeginRetryPayment', failedModel);
    },
    async onBack() {
      if (this.value.pager.page === 1) return;
      const newPageNumber = this.value.pager.page - 1;
      this.emitChange({
        ...this.value,
        pager: { ...this.value.pager, page: newPageNumber }
      });
    },
    async onForward() {
      if (
        this.value.pager.page <
        this.value.pager.total / this.value.pager.pageSize
      ) {
        const newPageNumber = this.value.pager.page + 1;
        this.emitChange({
          ...this.value,
          pager: { ...this.value.pager, page: newPageNumber }
        });
      }
    },
    emitChange(val: any) {
      this.$emit('input', val);
      this.$emit('change', val);
    }
  }
});

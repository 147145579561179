export enum CancellationReasons {
  TooExpensive = 'Too expensive',
  TooHard = 'Too hard to use',
  SystemNotRequired = 'System no longer required',
  MissingFeatures = 'Key features missing',
  BoughtAnotherSystem = 'Bought another system',
  Other = 'Other'
}

export enum CancellationTime {
  Immediate = 'Cancel immediately',
  EndOf = 'End of subscription period'
}



















import Vue from 'vue';

export default Vue.extend({
  name: 'WarningBanner',
  props: {
    warningText: {
      type: String,
      required: true,
      default: ''
    },
    cancelling: {
      type: Boolean,
      default: false
    }
  }
});

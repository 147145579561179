import Vue from 'vue';
export default {
  setSystemCost(state, cost) {
    state.systemCost = cost;
  },
  setSystemWorkforceLevel(state, workforceLevel) {
    state.systemWorkforceLevel = workforceLevel;
  },
  setSystemCancellationDate(state, payload) {
    state.systemCancellationDate = payload;
  },
  setOrganisation(state, payload) {
    state.organisation = payload;
  },
  setToken(state, payload) {
    state.token = payload;
  },
  setErrorMessage(state, payload) {
    state.errorMessage = payload;
  },
  resetErrorMessage(state) {
    state.errorMessage = '';
  },
  setCurrentUserId(state, currentUserId) {
    state.currentUserId = currentUserId;
  },
  setRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  setUserEmail(state, userEmail) {
    state.userEmail = userEmail;
  },
  setUserOrganisations(state, data) {
    state.organisations = data;
  },
  setApiUrl(state, url) {
    state.apiUrl = url;
  },
  setApiServerId(state, id) {
    state.apiServerId = id;
  },
  setRotifyUrl(state, url) {
    state.rotifyUrl = url;
  },
  setAnnualPlanExceeded(state, exceeded) {
    state.annualPlanExceeded = exceeded;
  },
  setProRataDate(state, proRataDate) {
    state.proRataDate = proRataDate;
  },
  initialiseStore(state) {
    if (localStorage.getItem('store')) {
      Object.assign(state, JSON.parse(localStorage.getItem('store')));
    }
    if (sessionStorage.getItem('store')) {
      Object.assign(state, JSON.parse(sessionStorage.getItem('store')));
    }
  },
  resetState(state, initialState) {
    for (const key in state) {
      if (key !== 'apiUrl') {
        Vue.set(state, key, initialState[key]);
      }
    }
  },
  setSubscriptionId(state, id) {
    state.subscriptionId = id;
  },
  setFutureSubscriptionExists(state, value) {
    state.futureSubscriptionExists = value;
  },
  setSmsSubscriptionId(state, value) {
    state.smsSubscriptionId = value;
  },
  setShouldCheckForLatestFailedPayment(state, value) {
    state.shouldCheckForLatestFailedPayment = value;
  },
  setPaymentCards(state, value) {
    state.paymentCards = value;
  },
  setTermsAndConditionsUrl(state, value) {
    state.termsAndConditionsUrl = value;
  },
  setPrivacyPolicyUrl(state, value) {
    state.privacyPolicyUrl = value;
  },
  setUserGuidesUrl(state, value) {
    state.userGuidesUrl = value;
  }
};

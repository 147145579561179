







































































































































































































































































import Vue from 'vue';
import { TabPanel, Tab, Card, NameOrb, ButtonField } from 'rm-design';
import LatestPayments, {
  ILatestPaymentsMetaData
} from '@/components/LatestPayments/LatestPayments.vue';
import BillingSummary from '@/components/BillingSummary/BillingSummary.vue';
import PaymentDetailsOverlay from '@/components/PaymentDetails/PaymentDetailsOverlay.vue';
import BillingDetailsOverlay from '@/components/PaymentDetails/BillingDetailsOverlay.vue';
import PlanSummary from '@/components/Plan/PlanSummary.vue';
import SavedCards from '@/components/SavedCards/SavedCards.vue';
import SmsPlanSummary from '@/components/SmsPlan/SmsPlanSummary.vue';
import FuturePlanSummary from '@/components/FuturePlan/FuturePlanSummary.vue';
import {
  IBillingData,
  IFutureBillingData
} from '@/components/BillingSummary/BillingSummary';
import CancelPlanModal from '@/components/Plan/CancelPlanModal.vue';
import BillingTabs from '@/constants/BillingTabs';
import ChangeCardConfirmationModal from '@/components/ChangeCardConfirmationModal.vue';
import RetryFailedPaymentModal from '@/components/PaymentModal/RetryFailedPaymentModal.vue';
import EnableSmsPaymentModal from '@/components/PaymentModal/EnableSmsPaymentModal.vue';
import CancelFutureSubscriptionModal from '@/components/CancelFutureSubscriptionModal.vue';
import ChangeOrganisationModal from '@/components/ChangeOrganisation/ChangeOrganisationModal.vue';
import AddCardOverlay from '@/components/AddCardOverlay.vue';
import { ActiveCardOptions } from '@/components/PaymentDetails/CardDetails.vue';
import { deleteCard } from '@/api/cards';
import {
  getSubscriptions,
  updatePaymentCard,
  ISubscriptionsDataResponse,
  ISubscriptionsSystem,
  cancelScheduledSubscriptionUpgrade,
  ICancelScheduledSubsUpgradeArg
} from '@/api/subscriptions';
import CancelSmsPlanModal from '@/components/SmsPlan/CancelSmsPlanModal.vue';
import ReactivateSubscriptionModal from '@/components/ReactivateSubscriptionModal.vue';
import {
  IFailedPaymentModel,
  IPaymentModel
} from '@/components/LatestPayments/interfaces';
import { startOfMonth, addMonths, addWeeks, format } from 'date-fns';
import { IGetInvoicesArg, getInvoices } from '@/api/invoices';
import ProfileMenuSkeleton from '@/components/ProfileMenuSkeleton.vue';
import { Skeleton, SkeletonItem } from 'rm-design';
import DangerModal from '@/components/DangerModal.vue';
import { mapActions } from 'vuex';
import { ICountry, getSystemCountries } from '@/api/organisations';

interface IHeaderCard {
  id: number;
  title: string;
  subtitle: string;
  iconColour: string;
  iconBackgroundColour: string;
  icon: string;
  updatedDate: string;
}

export default Vue.extend({
  name: 'Home',
  components: {
    LatestPayments,
    BillingSummary,
    PaymentDetailsOverlay,
    PlanSummary,
    SavedCards,
    Tab,
    TabPanel,
    Card,
    SmsPlanSummary,
    FuturePlanSummary,
    CancelPlanModal,
    ChangeCardConfirmationModal,
    RetryFailedPaymentModal,
    EnableSmsPaymentModal,
    CancelFutureSubscriptionModal,
    NameOrb,
    ButtonField,
    ChangeOrganisationModal,
    AddCardOverlay,
    CancelSmsPlanModal,
    ReactivateSubscriptionModal,
    ProfileMenuSkeleton,
    Skeleton,
    SkeletonItem,
    DangerModal,
    BillingDetailsOverlay
  },
  data() {
    return {
      BillingTabs,
      currentTab: BillingTabs.LatestPayments,
      loading: false,
      billingData: {} as ISubscriptionsDataResponse,
      showPaymentDetails: false,
      showBillingDetails: false,
      showCancelPlanModal: false,
      showChangeActiveCardConfirmationModal: false,
      changingActiveCardId: '',
      showRetryPaymentModal: false,
      paymentToRetry: null,
      showEnableSmsPaymentModal: false,
      showCancelFutureSubscriptionModal: false,
      showSwitchOrganisationModal: false,
      showAddCardOverlay: false,
      showCancelSmsPlanModal: false,
      failedPaymentModel: {} as IFailedPaymentModel,
      showReactivateSubscriptionModal: false,
      showReactivationPlan: false,
      reactivationId: '',
      deleteCardConfirmationModalIsOpen: false,
      cardIdToDelete: '',
      deletingCard: false,
      latestPaymentsMetaData: {
        dateRange: [
          startOfMonth(addMonths(new Date(Date.now()), -3)),
          new Date(Date.now())
        ],
        filters: {
          subscriptionType: null,
          status: null
        },
        pager: {
          page: 1,
          pageSize: 20,
          total: 0
        }
      } as ILatestPaymentsMetaData,
      latestPaymentData: new Array<IPaymentModel>(),
      gettingLatestPayments: false,
      organisationName: '' as string,
      countries: new Array<ICountry>()
    };
  },
  computed: {
    subId(): string {
      const id = this.billingData?.system?.subscriptionId;
      if (id) {
        return id;
      }
      return '';
    },
    systemData(): ISubscriptionsSystem | null {
      const data = this.billingData?.system;
      return data || null;
    },
    smsData(): IBillingData | null {
      const data = this.billingData?.sms;
      return data || null;
    },
    smsSubId(): string {
      let id = '';
      const subId = this.billingData?.sms?.subscriptionId;
      if (subId) {
        id = subId;
      }
      return id;
    },
    planCardTitle(): string {
      let title = 'Your Plan';
      if (this.$store.state.systemCancellationDate) {
        if (this.$store.state.futureSubscriptionExists) {
          title = 'Upgrade plan';
        } else {
          title = 'Cancelling plan';
        }
      }
      return title;
    },
    planCardSubtitle(): string {
      let sub = 'View Details';
      if (this.$store.state.systemCancellationDate) {
        sub = this.$store.state.futureSubscriptionExists
          ? 'View Details'
          : 'Reactivate plan';
      } else if (this.billingData.system) {
        sub = 'View Details';
      } else {
        sub = 'Sign Up';
      }
      return sub;
    },
    smsTitle(): string {
      if (this.billingData.sms) {
        return this.billingData.sms.cancellationDate !== null
          ? 'SMS Cancelling'
          : 'SMS Enabled';
      } else {
        return 'SMS Disabled';
      }
    },
    smsSubTitle(): string {
      const disabledMessage =
        'If you would like to turn SMS on, please call our support team on: 01924 252360';
      if (this.billingData.sms) {
        return this.billingData.sms.cancellationDate !== null &&
          this.billingData.system?.cancellationDate === null
          ? disabledMessage
          : 'View Details';
      } else {
        return disabledMessage;
      }
    },
    futureData(): IFutureBillingData | null {
      const data = this.billingData?.future;
      return data || null;
    },
    activeCardId(): string {
      let cardId = '';

      switch (this.currentTab) {
        case this.BillingTabs.Plan:
          cardId = this.billingData.system?.activeCard.id || '';
          break;
        case this.BillingTabs.SMSPlan:
          cardId = this.billingData.sms?.activeCard.id || '';
          break;
        case this.BillingTabs.FuturePlan:
          cardId = this.billingData.future?.cardDetails.id || '';
      }

      return cardId;
    },
    planActiveCardId(): string {
      return this.billingData.system?.activeCard.id || '';
    },
    workforceLevel(): number {
      return this.billingData?.system?.workforceLevel || 0;
    },
    workforceLevelTierLimit(): number {
      return this.billingData?.system?.tierLimit || 0;
    },
    headerCards(): IHeaderCard[] {
      return [
        {
          id: 1,
          title: this.planCardTitle,
          subtitle: this.planCardSubtitle,
          iconColour: '#F4BF36',
          iconBackgroundColour: '#FDF2D7',
          icon: 'fa fa-clone',
          updatedDate: ''
        },
        {
          id: 2,
          title: this.smsTitle,
          subtitle: this.smsSubTitle,
          iconColour: '#4DB6AC',
          iconBackgroundColour: '#DBF0EE',
          icon: 'fa fa-comment',
          updatedDate: ''
        },
        {
          id: 3,
          title: 'Payment Details',
          subtitle: 'View Details',
          iconColour: '#EF709D',
          iconBackgroundColour: '#FCE2EB',
          icon: 'fa fa-credit-card-alt',
          updatedDate: ''
        }
      ];
    },
    organisationFirstInitial(): string {
      return this.organisationName ? this.organisationName.charAt(0) : '';
    },
    activeCardOption(): string {
      let option = ActiveCardOptions.Subscription;
      if (this.currentTab === this.BillingTabs.SMSPlan) {
        option = ActiveCardOptions.SMS;
      } else if (this.currentTab === this.BillingTabs.FuturePlan) {
        option = ActiveCardOptions.FuturePlan;
      }
      return option;
    },
    showSwitchOrganisationMenuItem(): boolean {
      return this.$store.state.organisations?.length > 1;
    },
    smsCardIsEnabled(): boolean {
      return this.billingData.sms?.cancellationDate === null;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(['logOut', 'setPaymentCards']),
    async init() {
      this.organisationName = this.$store.state.organisation
        ? this.$store.state.organisation.name
        : '';
      this.getBillingData();
      this.setPaymentCards();
      this.getLatestPayments().then(() => {
        this.checkForFailedSubscriptionPayment();
      });

      await getSystemCountries().then(res => {
        if (res.data) {
          this.countries = res.data;
        }
      });
    },
    async getLatestPayments() {
      this.latestPaymentData = [];
      const now = new Date();
      let fromDate = now;
      let toDate = addWeeks(now, 4);

      if (this.latestPaymentsMetaData.dateRange[0]) {
        fromDate = this.latestPaymentsMetaData.dateRange[0];
      }
      if (this.latestPaymentsMetaData.dateRange[1]) {
        toDate = this.latestPaymentsMetaData.dateRange[1];
      }
      const arg: IGetInvoicesArg = {
        fromDate: format(fromDate, 'yyyy-MM-dd'),
        toDate: format(toDate, 'yyy-MM-dd 23:59:59'),
        pageSize: this.latestPaymentsMetaData.pager.pageSize,
        pageNumber: this.latestPaymentsMetaData.pager.page
      };
      if (this.latestPaymentsMetaData.filters.subscriptionType) {
        arg.subscriptionType = this.latestPaymentsMetaData.filters.subscriptionType.label;
      }
      if (this.latestPaymentsMetaData.filters.status) {
        arg.status = this.latestPaymentsMetaData.filters.status.id as string;
      }

      this.gettingLatestPayments = true;
      const response = await getInvoices(arg);
      this.gettingLatestPayments = false;

      if (!response || response.error) {
        this.$snotify.error(response ? response.message : '', 'Error');
        return;
      }

      if (response.data) {
        this.latestPaymentData = response.data.invoices;
        this.latestPaymentsMetaData.pager.total = response.data.total;
      }
    },
    async deleteCard() {
      if (this.cardIdToDelete.length) {
        this.deletingCard = true;
        const res = await deleteCard(this.cardIdToDelete);
        this.deletingCard = false;
        this.deleteCardConfirmationModalIsOpen = false;
        if (res && !res.error) {
          this.setPaymentCards();
          this.cardIdToDelete = '';
          this.$snotify.success('Card deleted', 'Success');
          return;
        }
        this.$snotify.error(
          res ? res.message : 'Failed to delete card',
          'Error'
        );
      }
    },
    onBeginDeleteCard(cardId: string) {
      this.cardIdToDelete = cardId;
      this.deleteCardConfirmationModalIsOpen = true;
    },
    onSubCancelled() {
      this.getBillingData();
    },
    async getBillingData() {
      const panel = this.$refs.tabPanel as any;
      panel.goToTab(BillingTabs.LatestPayments);
      this.loading = true;
      const res = await getSubscriptions();
      if (res && !res.error && res.data) {
        this.billingData = res.data;
      }
      const subId = this.billingData?.system?.subscriptionId;
      const smsSubId = this.billingData?.sms?.subscriptionId;
      if (subId) {
        this.$store.commit('setSubscriptionId', subId);
      }
      if (smsSubId) {
        this.$store.commit('setSmsSubscriptionId', smsSubId);
      }
      this.loading = false;
      const cost = this.billingData?.system?.previousInvoice?.cost;
      this.$store.commit('setSystemCost', cost ? cost : 0);
      const workforceLevel = this.billingData?.system?.workforceLevel;
      this.$store.commit(
        'setSystemWorkforceLevel',
        workforceLevel ? workforceLevel : 0
      );
      this.$store.commit(
        'setSystemCancellationDate',
        this.billingData?.system?.cancellationDate
      );
      this.$store.commit(
        'setAnnualPlanExceeded',
        this.billingData?.system?.annualPlanExceeded
      );
      this.$store.commit(
        'setProRataDate',
        this.billingData?.system?.proRataDate
      );

      if (this.billingData?.future) {
        this.$store.commit('setFutureSubscriptionExists', true);
      } else {
        this.$store.commit('setFutureSubscriptionExists', false);
      }
    },
    onBeginCancelPlan() {
      this.showCancelPlanModal = true;
    },
    onUpdatePlan() {
      this.$router.push('/update-plan');
    },
    onEditPlanClick() {
      if (this.systemData) {
        this.$router.push('/update-plan');
      } else {
        this.$router.push('/subscribe');
      }
    },
    async onBeginCancelSms() {
      this.showCancelSmsPlanModal = true;
    },
    onBeginCancelFuturePlan() {
      this.showCancelFutureSubscriptionModal = true;
    },
    async onCancelFutureSubscription(continueCurrentSubscription: boolean) {
      const config = {
        id: this.billingData.future.id,
        continueCurrentSubscription
      } as ICancelScheduledSubsUpgradeArg;
      this.loading = true;
      const response = await cancelScheduledSubscriptionUpgrade(config);
      if (!response.error) {
        await this.getBillingData();
        this.$snotify.success(
          'Your future subscription has been cancelled',
          'Success'
        );
        this.showCancelFutureSubscriptionModal = false;
      } else {
        this.$snotify.error('Error cancelling future plan', 'Error');
      }
      this.loading = false;
    },
    showReactivationModal(isSystem: boolean) {
      this.showReactivateSubscriptionModal = true;
      this.reactivationId = isSystem ? this.subId : this.smsSubId;
      this.showReactivationPlan = isSystem;
    },
    onTabChange(tab) {
      tab = tab.replace(/\s/g, '');
      this.currentTab = BillingTabs[tab];
    },
    onBeginChangeActiveCard(cardId) {
      this.changingActiveCardId = cardId;
      this.showChangeActiveCardConfirmationModal = true;
    },
    onCloseChangeActiveCardConfirmationModal() {
      this.changingActiveCardId = '';
      this.showChangeActiveCardConfirmationModal = false;
    },
    async onChangeActiveCard(cardId) {
      const newCard = this.$store.state.paymentCards.find(x => x.id === cardId);
      this.onCloseChangeActiveCardConfirmationModal();
      if (this.billingData && newCard) {
        let subscriptionId = '';
        switch (this.currentTab) {
          case this.BillingTabs.Plan:
            if (this.billingData.system) {
              subscriptionId = this.billingData.system.subscriptionId;
            }
            break;
          case this.BillingTabs.SMSPlan:
            if (this.billingData.sms) {
              subscriptionId = this.billingData.sms.subscriptionId;
            }
            break;
          case this.BillingTabs.FuturePlan:
            if (this.billingData.future) {
              subscriptionId = this.billingData.future.subscriptionId;
            }
        }

        const response = await updatePaymentCard({ subscriptionId, cardId });

        if (!response.error) {
          this.$snotify.success('Active card updated', 'Success');
          await this.getBillingData();
        } else {
          this.$snotify.error('Failed to update active card', 'Failure');
        }
      } else {
        const message = 'Error updating active card';
        this.$snotify.error(message, 'Error');
      }
    },
    retryPaymentUsingInvoiceId(id: string) {
      const invoice = this.latestPaymentData.find(x => x.id === id);
      if (invoice) {
        this.onBeginRetryPayment({
          invoiceId: invoice.id,
          paymentMethodId: invoice.paymentMethodId,
          subscriptionId: invoice.subscriptionId,
          requiresAction: false,
          hostedInvoiceUrl: ''
        });
      } else {
        this.$snotify.error('Cannot find invoice', 'Error');
      }
    },
    onBeginRetryPayment(failedModel: IFailedPaymentModel) {
      this.showRetryPaymentModal = true;
      this.failedPaymentModel = failedModel;
    },
    onCloseRetryPaymentModal() {
      this.showRetryPaymentModal = false;
      this.paymentToRetry = null;
      this.$store.commit('setShouldCheckForLatestFailedPayment', false);
    },
    onRedirectToRotify() {
      location.href = this.$store.state.rotifyUrl;
    },
    headerCardClick(cardId) {
      const tabPanel: any = this.$refs.tabPanel;
      if (cardId === 1) {
        if (
          this.$store.state.systemCancellationDate &&
          !this.$store.state.futureSubscriptionExists
        ) {
          this.showReactivationModal(true);
        } else if (this.billingData?.system) {
          tabPanel.goToTab('Plan');
        } else {
          this.$router.push('/subscribe');
        }
      }

      if (cardId === 2 && !this.smsCardIsEnabled) {
        // Temp disabled SMS sign up
        return;
      }

      if (cardId === 2) {
        if (this.billingData?.sms) {
          if (
            this.billingData.sms.cancellationDate !== null &&
            this.billingData.system?.cancellationDate === null
          ) {
            this.showReactivationModal(false);
          } else {
            tabPanel.goToTab('SMS Plan');
          }
        } else if (this.billingData.system) {
          this.showEnableSmsPaymentModal = true;
        } else {
          this.$router.push('/subscribe');
        }
      }

      if (cardId === 3) {
        this.showPaymentDetails = true;
      }
    },
    onBeginAddNewCard() {
      this.showAddCardOverlay = true;
    },
    onCloseAddCardOverlay() {
      this.showAddCardOverlay = false;
    },
    async onAddNewCard() {
      await this.getBillingData();
    },
    checkForFailedSubscriptionPaymentOld() {
      const matchingPayment = this.latestPaymentData.find(payment => {
        return (
          payment.subscriptionId ===
            this.$route.params.failedSubscriptionPayment &&
          payment.status === 'open'
        );
      });

      if (matchingPayment) {
        this.onBeginRetryPayment({
          invoiceId: matchingPayment.id,
          paymentMethodId: matchingPayment.paymentMethodId,
          subscriptionId: matchingPayment.subscriptionId,
          requiresAction: false,
          hostedInvoiceUrl: ''
        });
      } else {
        return;
      }
    },
    checkForFailedSubscriptionPayment(): void {
      const specificFailedPayment = this.latestPaymentData.find(payment => {
        return (
          payment.subscriptionId ===
            this.$route.params.failedSubscriptionPayment &&
          payment.status === 'open'
        );
      });
      if (specificFailedPayment) {
        this.onBeginRetryPayment({
          invoiceId: specificFailedPayment.id,
          paymentMethodId: specificFailedPayment.paymentMethodId,
          subscriptionId: specificFailedPayment.subscriptionId,
          requiresAction: false,
          hostedInvoiceUrl: ''
        });
        return;
      }
      if (!this.$store.state.shouldCheckForLatestFailedPayment) {
        return;
      }
      const latestPayment = this.latestPaymentData.length
        ? this.latestPaymentData[0]
        : null;
      const latestPaymentHasFailed = latestPayment?.status === 'open';
      if (latestPayment && latestPaymentHasFailed) {
        this.onBeginRetryPayment({
          invoiceId: latestPayment.id,
          paymentMethodId: latestPayment.paymentMethodId,
          subscriptionId: latestPayment.subscriptionId,
          requiresAction: false,
          hostedInvoiceUrl: ''
        });
      }
    },
    async onRetryPaymentSuccess() {
      this.init();
    }
  }
});

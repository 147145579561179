var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_c('div',{staticClass:"check-list-item__icon-container rm-p--4",class:{
        'check-list-item__icon-container--error':
          _vm.status === _vm.StatusTypes.Error,
        'check-list-item__icon-container--success':
          _vm.status === _vm.StatusTypes.Success
      },attrs:{"id":"check-list-item__icon-container"}},[(_vm.status === _vm.StatusTypes.Error)?_c('i',{staticClass:"fa fa-minus",attrs:{"aria-hidden":"true"}}):_vm._e(),(_vm.status !== _vm.StatusTypes.Error)?_c('i',{staticClass:"fa fa-check ",attrs:{"aria-hidden":"true"}}):_vm._e()])]),_c('p',{staticClass:"rm-p--4 rm-pl--12",class:{
      'check-list-item__label--default': _vm.status === _vm.StatusTypes.Default
    },attrs:{"id":"check-list-item__label"}},[_c('small',[_vm._v(_vm._s(_vm.label))])])])}
var staticRenderFns = []

export { render, staticRenderFns }










































































import Vue from 'vue';
import { ButtonField, Overlay } from 'rm-design';
import PaymentCard from '@/components/PaymentDetails/PaymentCard.vue';
import CardDetails from '@/components/PaymentDetails/CardDetails.vue';
import { Carousel, Slide } from 'vue-carousel';
import CardDetailsForm from '@/components/PaymentDetails/models/CardDetailsForm';
import { PaymentDetailsIds } from '@/components/PaymentDetails/constants/PaymentDetailsIds';
import EventHub from '@/eventHub';
import { ICard } from '@/components/PaymentDetails/PaymentDetailsInterfaces';

export default Vue.extend({
  name: 'PaymentDetailsOverlay',
  components: {
    Overlay,
    PaymentCard,
    Carousel,
    Slide,
    ButtonField,
    CardDetails
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show(shouldShow) {
      if (shouldShow) {
        this.form = new CardDetailsForm();
      }
    }
  },
  computed: {
    savedCardOptions(): ICard[] {
      return this.$store.state.paymentCards;
    }
  },
  data() {
    return {
      addNewCard: false,
      submitAttempted: false,
      form: new CardDetailsForm(),
      showConfirmModal: false,
      currentCardId: '',
      PaymentDetailsIds: PaymentDetailsIds,
      submittingCard: false
    };
  },
  methods: {
    onDeleteCard(cardId: string) {
      this.$emit('deleteCard', cardId);
    },
    async onSubmit() {
      this.submittingCard = true;
      EventHub.$emit('submit');
    },
    onCardAdded() {
      this.submittingCard = false;
      this.$snotify.success('Card added', 'Success');
      this.addNewCard = false;
      this.$emit('close');
    }
  }
});

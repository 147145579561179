



































































import { ButtonField, PinBoxes } from 'rm-design';
import Vue from 'vue';
import MailOpenedImage from '@/assets/mail-opened.png';
import MailSentImage from '@/assets/mail-sent.png';
import RotifyLogo from '@/assets/Logo-Purple.svg';
import { sendVerificationCodeToUser, verify } from '@/api/user';
import { mapActions } from 'vuex';

export default Vue.extend({
  name: 'Verify',
  components: { ButtonField, PinBoxes },
  data() {
    return {
      MailOpenedImage,
      MailSentImage,
      RotifyLogo,
      verificationCodeArr: ['', '', '', '', '', ''],
      verifying: false,
      verified: false,
      sendingVerificationCode: false
    };
  },
  computed: {
    userEmail(): string {
      return this.$store.state.userEmail;
    }
  },
  methods: {
    ...mapActions(['logOut']),
    async onVerify() {
      this.verifying = true;
      const arg = {
        verificationCode: this.verificationCodeArr.join(''),
        username: this.userEmail,
        apiServerId: this.$store.state.apiServerId
      };
      const res = await verify(arg);
      this.verifying = false;
      if (res && !res.error) {
        this.verified = true;
      } else {
        this.$snotify.error(res ? res.message : 'Failed to verify', 'Error');
      }
    },
    async sendVerificationEmail() {
      this.sendingVerificationCode = true;
      const res = await sendVerificationCodeToUser();
      this.sendingVerificationCode = false;
      if (res && !res.error) {
        this.$snotify.success('Verification email sent', 'Success');
      } else {
        this.$snotify.error(
          res ? res.message : 'Failed to send verification email',
          'Error'
        );
      }
    }
  }
});

<template>
  <div style="display: flex; justify-content: center;">
    <div v-for="i in maxSteps" :key="i">
      <div
        class="snake-step-tracker__bar rm-m-sides--8"
        :class="{ 'snake-step-tracker__bar--active': i <= currentStep }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SnakeStepTracker',
  props: {
    maxSteps: {
      type: Number,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    }
  }
};
</script>

<style scoped>
.snake-step-tracker__bar {
  width: 40px;
  height: 4px;
  border-radius: 100px;
  background-color: #d7d7d7;
  transition: background-color 250ms ease-in-out, transform 150ms ease;
}

.snake-step-tracker__bar--active {
  background-color: #383838;
}
</style>

import { httpHandler } from '@/utils/ApiResponseHandler';
import { IApiResponse } from '@/interfaces/common';
export interface ISubscriptionsDataResponse {
  future?: any;
  system?: ISubscriptionsSystem;
  sms?: any;
}
export interface ISubscriptionsSystemInvoice {
  cost: number;
  createdDateTime: string;
  currency: string;
  customerEmail: string;
  id?: string;
  invoiceLines: {
    cost: number;
    description: string;
    id: string;
  }[];
  invoiceNumber?: number;
  invoicePdfUrl?: string;
  paymentDate?: string;
  periodEndDateTime: string;
  periodStartDateTime: string;
  planName: string;
  quantity: number;
  status: string;
}
export interface ISubscriptionsSystem {
  activeCard: {
    brand: string;
    cardHolderName: string;
    expiryMonth: number;
    expiryYear: number;
    id: string;
  };
  billingType: string;
  planName: string;
  cancellationDate?: string;
  nextInvoice: ISubscriptionsSystemInvoice;
  previousInvoice: ISubscriptionsSystemInvoice;
  subscriptionId: string;
  workforceLevel: number;
  tierLimit: number;
  proRataDate: string;
  annualPlanExceeded: boolean;
}
export async function getSubscriptions(): Promise<
  IApiResponse<ISubscriptionsDataResponse>
> {
  return httpHandler.get('/subscriptions');
}
export interface ISubscriptionArg {
  planId: string;
  cardId: string;
  workforceLevel?: number;
  monthly?: boolean;
  additionalPlans?: Array<string>;
}

export async function createSubscription(data: ISubscriptionArg): Promise<any> {
  return httpHandler.post('/subscriptions', data);
}

export async function updateSubscription(data: ISubscriptionArg): Promise<any> {
  return httpHandler.put('/subscriptions', data);
}

export async function resumeSubscription(id: string): Promise<any> {
  return httpHandler.put(`/subscriptions/resume/${id}`);
}
export interface ICancelSubArg {
  subscription: string;
  reason?: string;
  immediate?: boolean;
  password?: string;
}
export async function cancelSubscription(data: ICancelSubArg): Promise<any> {
  return httpHandler.put('/subscriptions/cancel', data);
}

export async function getScheduledSubscriptionUpgrades(): Promise<any> {
  return httpHandler.get('/subscriptions/schedules/upgrades');
}

export interface ICancelScheduledSubsUpgradeArg {
  id: string;
  continueCurrentSubscription: boolean;
}
export async function cancelScheduledSubscriptionUpgrade(
  arg: ICancelScheduledSubsUpgradeArg
): Promise<any> {
  return httpHandler.put(
    `/subscriptions/schedules/cancel/?SubscriptionScheduleId=${arg.id}&continueCurrentSubscription=${arg.continueCurrentSubscription}`
  );
}
export interface IUpdatePaymentCardArg {
  subscriptionId: string;
  cardId: string;
}
export async function updatePaymentCard(
  arg: IUpdatePaymentCardArg
): Promise<IApiResponse<any>> {
  return httpHandler.put('/subscriptions/paymentcard', {
    subscriptionId: arg.subscriptionId,
    paymentMethodId: arg.cardId
  });
}
